/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useEffect, useState} from 'react'
//import {useIntl} from 'react-intl'
import {PageLink, PageTitle} from '../../../_metronic/layout/core'
import { getMark, getQtdMark } from '../../services/markService'
import { searchSubstitut } from '../../utils/search'
import { Loading } from '../components/Loadings/loading'
import { SearchInput } from '../components/SearchInput'
import { useToast } from '../components/Toats'
import { MyPortal } from './my-portal'
import { getCredential } from '../../services/credentialService'
//import { MyPortal } from './components/my-portal'

const portalsBreadcrumbs: Array<PageLink> = [
  {
    title: 'Dashboard',
    path: '/dashboard',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const PortalPage: FC = () => {
    const { toggleToast } = useToast();
    const [filterMarks, setFilterMarks] = useState<boolean | null>(null);
    const [marks, setMarks] = useState<any>([]);
    const [qtdMarks, setQtdMarks] = useState<any>({
      all: 0,
      active: 0,
      notActive: 0
    });

    const [markLoading, setMarkLoading] = useState<any>(true);
    const [credentialLoading, setCredentialLoading] = useState<any>(true);
    const [credentials, setCredentials] = useState<any>([]);
    const [mySearch, setMySearch] = useState<any[]>([]);
    const [control, setControl] = useState(false);

    const [largura, setLargura] = useState(window.innerWidth);

    const getMarks = async () => {
        try {
          setMarkLoading(true)
          const myMarks = await getMark(filterMarks)
          setMarks(myMarks)

          const search_marks = mySearch.length > 0 ? 
            searchSubstitut(myMarks, mySearch)
          : []
          setMySearch(search_marks)

        } catch (error) {
          console.log(error)
          toggleToast("Erro ao buscar os portais!", "error")
        }finally{
          setMarkLoading(false)
        }
    }

    const getQtdMarks = async () => {
      try {
        const qtdMarks = await getQtdMark()
        setQtdMarks(qtdMarks)

      } catch (error) {
        console.log(error)
      }
    }
    const atualizarTamanhoDaTela = () => {
      setLargura(window.innerWidth);
    };

    const getCredentials = async () => {
      try {
        setCredentialLoading(true)
        const myCredentials = await getCredential()
        setCredentials(myCredentials)
        setCredentialLoading(false)

      } catch (error) {
        console.log(error)
      }
    }

    const refresh = () => {
      getCredentials()
    }

    useEffect(() => {
      getMarks()
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filterMarks]);


    useEffect(() => {
      getMarks()
      getQtdMarks()
      getCredentials()

      window.addEventListener('resize', atualizarTamanhoDaTela);
      return () => {
        window.removeEventListener('resize', atualizarTamanhoDaTela);
      };

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    // if (markLoading) {
    //     return <h1>Carrengando...</h1>;
    // }else if(marks.length === 0){
    //     return <h1>Não há portais cadastrados!</h1>;
    // }

  return (
    <div className='column g-5 g-xl-8'>
      <div style={{marginBottom: '2rem', padding: '0rem 1.5rem 1rem 0rem'}}>
        <div style={{display: 'flex', gap: '1rem', flexWrap: 'wrap', width: ""}}>

          <SearchInput 
            className='form-control form-control-solid ps-14 bg-body'
            placeholder='Buscar Portal'
            myDate={marks}
            setMySearch={setMySearch}
            control={control}
            style={{width: largura < 1200 ? "100%" : "300px"}}
            setControl={setControl}
            searchParameter="name"
          />

          {largura > 1200 ?
            <div className='card-toolbar nav nav-tabs' data-kt-buttons='true'>
              <a
                style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}
                className='btn btn-sm btn-color-muted btn-active btn-active-dark active px-5 me-1 nav-item'
                data-bs-toggle="tab"
                href="#"
                onClick={() => {
                  setFilterMarks(null)
                }}                
              >
                <span>Todos</span>
                <span 
                  className='badge badge-dark'
                  style={{marginLeft: '10px'}}
                >
                  {qtdMarks.all}
                </span>
              </a>
              <a
                style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}
                className='btn btn-sm btn-color-muted btn-active btn-active-success px-4 me-1 nav-item'
                
                data-bs-toggle="tab"
                href="#"
                onClick={() => {
                  setFilterMarks(true)
                }}

              >
                <span>Ativos</span>
                <span 
                  className='badge badge-success'
                  style={{marginLeft: '10px'}}
                >
                  {qtdMarks.active}
                </span>
              </a>
              <a
                style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}
                className='btn btn-sm btn-color-muted btn-active btn-active-danger px-4 nav-item'
                
                data-bs-toggle="tab"
                href="#"
                onClick={() => {
                  setFilterMarks(false)
                }}
                
              >
                <span>Desativados</span>
                <span 
                  className='badge badge-danger'
                  style={{marginLeft: '10px'}}
                >
                  {qtdMarks.notActive}
                </span>
              </a>
            </div>
              :
            <div className='me-0 dropdown' style={{width: '100%'}}>
              <button
                //style={{color: 'white'}}
                //disabled={loadingAvaiable || loading || report ? !report.template ? true : false : true}
                className='btn btn-lg btn-secondary'
                style={{width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}
                type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false"
              >
                <span>Buscar pelo Status </span>
                <i className='bi bi-three-dots fs-3'></i>
              </button>

              <div className='dropdown-menu w-150px w-md-150px dropdown-menu-end' aria-labelledby="dropdownMenuButton1" style={{padding: '1rem'}}>
                <div className='card-toolbar nav nav-tabs' data-kt-buttons='true'>
                  <a
                    style={{display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%'}}
                    className='btn btn-sm btn-color-muted btn-active btn-active-dark active px-5 me-1 nav-item'
                    data-bs-toggle="tab"
                    href="#"
                    onClick={() => {
                      setFilterMarks(null)
                    }}                
                  >
                    <span>Todos</span>
                    <span 
                      className='badge badge-dark'
                      style={{marginLeft: '10px'}}
                    >
                      {qtdMarks.all}
                    </span>
                  </a>
                  <a
                    style={{display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%'}}
                    className='btn btn-sm btn-color-muted btn-active btn-active-success px-4 me-1 nav-item'
                    
                    data-bs-toggle="tab"
                    href="#"
                    onClick={() => {
                      setFilterMarks(true)
                    }}

                  >
                    <span>Ativos</span>
                    <span 
                      className='badge badge-success'
                      style={{marginLeft: '10px'}}
                    >
                      {qtdMarks.active}
                    </span>
                  </a>
                  <a
                    style={{display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%'}}
                    className='btn btn-sm btn-color-muted btn-active btn-active-danger px-4 nav-item'
                    
                    data-bs-toggle="tab"
                    href="#"
                    onClick={() => {
                      setFilterMarks(false)
                    }}
                    
                  >
                    <span>Desativados</span>
                    <span 
                      className='badge badge-danger'
                      style={{marginLeft: '10px'}}
                    >
                      {qtdMarks.notActive}
                    </span>
                  </a>
                </div>
              </div>
            </div>
            }
        </div>
      </div>

      {markLoading ? 
        (
          <div style={{display: 'flex', justifyContent: 'center', height: '30rem', alignItems: 'center'}}>
            <Loading myClass='spinner-border' style={{width: '3rem', height: '3rem'}}/>
          </div>
        )
      : 
      marks.length > 0 ? 
        (<div className='row bg-light border border-gray-300 border-dashed' 
            style={{
              width: '100%', 
              margin: '0rem', 
              padding: '1rem', 
              borderRadius: '10px', 
              paddingTop: '2rem'
            }}>
            {
              control === false ? 
                marks.map((mark: any, index: number) => {
                    return (
                        <div key={mark.id ? mark.id : index} className='col-md-3' style={{marginBottom: '1.5rem'}}>
                            <MyPortal 
                              className='card' 
                              color='dark' 
                              mark={mark}
                              credentials={credentials.filter((credential: any) => credential.mark && credential.mark.sub_name === mark.sub_name)}
                              refreshCredentials={() => {
                                refresh()
                              }}
                            />
                        </div>
                    );
                })
              :
                mySearch.length !== 0 ? 
                  mySearch.map((mark: any, index: number) => {
                    return (
                        <div key={mark.id ? mark.id : index} className='col-md-3' >
                            <MyPortal 
                              className='card' 
                              color='dark' mark={mark}
                              credentials={credentials.filter((credential: any) => credential.mark && credential.mark.sub_name === mark.sub_name)}
                              refreshCredentials={() => {
                                refresh()
                              }}
                            />
                        </div>
                    );
                  })
                :
                  <div>Não há portais com esse nome!</div>
            }
          </div>) 
      : 
        <div className='d-flex justify-content-center bg-light border border-gray-300 border-dashed' style={{padding: '2rem'}}>
          <span className='text-muted fw-semibold text-muted d-block fs-4'>
              Portais não encontrados!
          </span>
        </div>
    }
    </div>
  )
}

const PortalsWrapper: FC = () => {
  //const intl = useIntl()

  return (
    <>
      <PageTitle breadcrumbs={portalsBreadcrumbs}>
        Gerenciamento de Portais</PageTitle>
      <PortalPage />
    </>
  )
}

export {PortalsWrapper}
