import { useEffect, useCallback } from "react";
import { io, Socket } from "socket.io-client";
import { useAuth } from "../modules/auth";

let socket: Socket | null = null; // Socket global
const listeners = new Map<string, Set<Function>>(); // Map global para eventos

const useSocket = () => {
  const { currentUser } = useAuth();

  useEffect(() => {
    if (currentUser && currentUser.id && !socket) {
      // Cria a conexão do socket apenas se ainda não existir
      socket = io(process.env.REACT_APP_API_URL_SOCKET, {
        transports: ["websocket"],
        reconnection: true,
        reconnectionAttempts: Infinity,
      });

      socket.on("connect", () => {
        console.log("Conectado ao servidor Socket.IO:");
        socket!.emit("register", currentUser.id);
      });

      socket.on("disconnect", () => {
        console.log("Desconectado do servidor Socket.IO");
      });
    }

    // Não desconecta o socket global
    return () => {};
  }, [currentUser]);

  const on = useCallback((event: string, callback: Function) => {
    if (socket) {
      if (!listeners.has(event)) {
        listeners.set(event, new Set());
        socket.on(event, (...args) => {
          listeners.get(event)?.forEach((cb) => cb(...args));
        });
      }
      listeners.get(event)!.add(callback); // Adiciona o callback
    }
  }, []);

  const off = useCallback((event: string, callback: Function) => {
    if (socket && listeners.has(event)) {
      const eventListeners = listeners.get(event);
      eventListeners!.delete(callback);

      if (eventListeners!.size === 0) {
        listeners.delete(event); // Remove o evento se não houver mais callbacks
        socket.off(event);
      }
    }
  }, []);

  return { socket, on, off };
};

export default useSocket;

// import { useEffect, useCallback } from "react";
// import { io, Socket } from "socket.io-client";
// import { useAuth } from "../modules/auth";

// let socket: any = null;
// const listeners = new Map();

// const useSocket = () => {
//   const { currentUser } = useAuth();

//   useEffect(() => {
//     if (currentUser && currentUser.id && !socket) {
//       socket = io(process.env.REACT_APP_API_URL_SOCKET, {
//         transports: ["websocket"],
//         reconnection: true,
//         reconnectionAttempts: Infinity,
//       });

//       socket.on("connect", () => {
//         console.log("Conectado ao servidor Socket.IO:");
//         socket.emit("register", currentUser.id);
//       });

//       socket.on("disconnect", () => {
//         console.log("Desconectado do servidor Socket.IO");
//       });
//     }

//     return () => {};
//   }, [currentUser]);

//   const on = useCallback((event: string, callback: Function) => {
//     if (socket) {
//       if (!listeners.has(event)) {
//         listeners.set(event, new Set());
//         socket.on(event, (...args) => {
//           listeners.get(event).forEach((cb) => cb(...args));
//         });
//       }
//       listeners.get(event).add(callback);
//     }
//   }, []);

//   const off = useCallback((event: string, callback: Function) => {
//     if (socket && listeners.has(event)) {
//       const eventListeners = listeners.get(event);
//       eventListeners.delete(callback);

//       if (eventListeners.size === 0) {
//         socket.off(event);
//         listeners.delete(event);
//       }
//     }
//   }, []);

//   return { socket, on, off };
// };

// export default useSocket;




// import { useEffect, useCallback } from "react";
// import { io, Socket } from "socket.io-client";
// import { useAuth } from "../modules/auth";

// let socket: Socket | null = null;

// const useSocket = () => {
//   const { currentUser } = useAuth();

//   useEffect(() => {
//     if (currentUser && currentUser.id && !socket) {
//       socket = io(process.env.REACT_APP_API_URL_SOCKET, {
//         transports: ["websocket"],
//         reconnection: true,
//         reconnectionAttempts: Infinity,
//       });

//       socket.on("connect", () => {
//         console.log("Conectado ao servidor Socket.IO:");
//         socket!.emit("register", currentUser.id);
//       });

//       socket.on("disconnect", () => {
//         console.log("Desconectado do servidor Socket.IO");
//       });
//     }

//     return () => {
//       if (socket) {
//         socket.disconnect();
//         socket = null; // Limpa a instância ao desmontar
//       }
//     };
//   }, [currentUser]);

//   const on = useCallback(
//     (event: any, callback: any) => {
//       if (socket) {
//         socket.on(event, callback);
//       }
//     },
//     []
//   );

//   const off = useCallback(
//     (event: any) => {
//       if (socket) {
//         socket.off(event);
//       }
//     },
//     []
//   );

//   return { socket, on, off };
// };

// export default useSocket;