import React, { useEffect, useState } from 'react';
import { Worker, Viewer } from '@react-pdf-viewer/core';
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';
import { linkPDF } from '../../../../../../../../utils/savePDF';
import { KTSVG } from '../../../../../../../../../_metronic/helpers';
import { ModalDeleteTemplate } from '../CkEditor/components/ModalDeleteTemplateProposal';

type Props = {
    editorStateV2: any,
    myTemplates: any[],
    setInitialHtml: any
    setMyTemplate: any
    myTemplate: any,
    formik: any
    loadingTemplates: boolean
    refresh: any
    typePage: string
    linkPDFVariable: any
}

export function PreviewTemplate({
  editorStateV2, 
  myTemplates, 
  setInitialHtml, 
  setMyTemplate, 
  myTemplate, 
  formik, 
  loadingTemplates,
  refresh,
  typePage,
  linkPDFVariable
}: Props) {

  const [url, setUrl] = useState<any>(null)
  const newPlugin = defaultLayoutPlugin();
  const [pdfLoading, setPdfLoading] = useState(false);
  const [menuSelection, setMenuSelection] = useState("GERACAO");
  const [largura, setLargura] = useState(window.innerWidth);

  const [showCreateAppModalDelete, setShowCreateAppModalDelete] = useState<boolean>(false)
  const [template, setTemplate] = useState<any>(null)

  const selectTemplate = (id: string) => {
        const selectTemplateOne = myTemplates.find((template) => template.id === id)
        if(selectTemplateOne){
          setMyTemplate(selectTemplateOne)
          setInitialHtml(selectTemplateOne.textHTML)
        }else{
          setMyTemplate({
            description: null,
            id: null,
            textHTML: "<div>Crie o seu template ou escolha um template disponivel. </div>",
            type: "private"
          })
          setInitialHtml("<div>Crie o seu template ou escolha um template disponivel. </div>")
        } 
        
    }

    const previewPDF = async () => {
      try {
        setPdfLoading(true)
        const myReturnLink = await linkPDF(editorStateV2.__html)
        setUrl(myReturnLink) 
      
      } catch (error) {
        console.log(error)  
      }finally{
        setPdfLoading(false)
      }
    }

    const previewPDFDocument = () => {
      if(linkPDFVariable){
        setUrl(linkPDFVariable)
      }
    }

    const handleClickDelete = (event: any, template: string) => {
      event.stopPropagation();

      setTemplate(template)
      setShowCreateAppModalDelete(true)
    }

    // useEffect(() => {
    //   async function link (){
    //     // const url = await linkPDF(editorStateV2.__html)
    //     // setUrl(url)
    //     const myreurtn = await linkPDF(editorStateV2.__html)
    //     setUrl(myreurtn)
    //   }
    //   link()

    // }, [editorStateV2.__html])

    const atualizarTamanhoDaTela = () => {
      setLargura(window.innerWidth);
    };


    useEffect(() => {
      window.addEventListener('resize', atualizarTamanhoDaTela);
      return () => {
        window.removeEventListener('resize', atualizarTamanhoDaTela);
      };
  
    }, []);

  return (
    <div style={{ flex: '1', height: '50rem', width: '100%'}}>
        <div style={{marginBottom: '1rem', display: 'flex', gap: '1.5rem', justifyContent: 'space-between', flexDirection: largura < 1200 ? 'column' : 'row'}}>
            <h3 className='align-items-start flex-column' style={{display: "flex"}}>
                <span className='card-label fw-bold fs-3 mb-1'>Visualização do template da proposta</span>
                <span className='text-muted fw-semibold fs-7'>Renderização da proposta com valores</span>
            </h3>
            {loadingTemplates 
              ? 
                <><h3>Carregando...</h3></> 
              :
                <div style={{display: 'flex', gap: '1rem'}}>
                  
                  <div style={{display: 'flex', alignItems: 'center', gap: '1rem'}}>
                    <h3 className='align-items-start flex-column' style={{display: "flex"}}>
                        <span className='text-muted fw-semibold fs-6'>{typePage ? `.${typePage}` : ''}</span>
                    </h3>
                    <div className="dropdown">
                      <button type="button" className="btn btn-lg btn-light dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false" data-bs-auto-close="outside">
                      <span>{myTemplate.description ? myTemplate.description : "Selecione um template"} </span>
                      </button>
                      <div className="dropdown-menu dropdown-menu-end p-4" style={{width: '30rem',}}>
                        <div>
                            <div
                                style={{maxHeight: '20rem', overflowY: 'auto', overflowX: "clip"}}
                                //style={{maxHeight: '20rem', overflowY: 'auto'}}
                            >
                            <button
                                className={`btn btn-sm btn-color-muted px-5 me-1 nav-item`}
                                style={{display: 'flex', gap: '1rem', alignItems: 'center', justifyContent: 'space-between', width: '100%'}}
                                data-bs-toggle="tab"
                                onClick={()=> {
                                selectTemplate("")
                                formik.setValues({...formik.values, idTemplate: null})
                                }}
                            >
                                <span style={{textAlign: 'start'}}>
                                    Selecione o template
                                </span>
                            </button>

                            {myTemplates && myTemplates.map((template: any) => {
                                //const exist_template = formik.values.idsTemplates.find((id) => id === template.id)
                                const exist_template = formik.values.idTemplate === template.id ? true : false
                                return (
                                    <button
                                        className={`btn btn-sm btn-color-muted ${myTemplate.id === template.id ? "border border-solid border-secondary" : ""} ${exist_template ? "btn-active-primary active" : ""} px-5 me-1 nav-item`}
                                        style={{display: 'flex', gap: '1rem', alignItems: 'center', justifyContent: 'space-between', width: '100%'}}
                                        data-bs-toggle="tab"
                                        onClick={()=> {
                                        
                                        selectTemplate(template.id)
                                        // let formik_templates = formik.values.idsTemplates;
                                        // const result = formik_templates.map(id => myTemplates.find(template => template.id === id) || null);
                                        // const index = result.findIndex(template=> template.type_template === "DETALHADO");
                                        // if(index === -1){
                                        //     formik_templates.push(template.id)
                                        // }else{
                                        //     formik_templates[index] = template.id
                                        // }
                                        // formik.setValues({...formik.values, idsTemplates: formik_templates})
                                        formik.setValues({...formik.values, idTemplate: template.id})
                                        }}
                                    >
                                          <span style={{textAlign: 'start'}}>
                                              {template.description ? template.description : "Não identificado"} 
                                          </span>
  
                                          <span style={{display: 'flex', gap: '1rem'}}>
                                            <span 
                                              className={
                                                `badge badge-light-${
                                                    template.type !== null
                                                    ? template.type === "private"
                                                    ? "primary"
                                                    : template.type === "public"
                                                    ? "success"
                                                    : "secondary"
                                                    : "secondary"
                                                } fs-7 fw-semibold`
                                              }
                                            >
                                                {template.type ? template.type : "Não identificado"} 
                                            </span>

                                            <button
                                              disabled={template.type && template.type === "public" ? true : false}
                                              onClick={(event: any) => {
                                                handleClickDelete(event, template);
                                              }}
                                              //href='#'
                                              className='btn btn-icon btn-bg-light btn-sm bg-hover-primary text-hover-light'
                                              style={{height: '2rem'}}
                                            >
                                                <KTSVG
                                                  path='/media/icons/duotune/general/gen027.svg'
                                                  className='svg-icon-3 text-primary'
                                                />
                                            </button>
                                          </span>
                                    </button>
                                )
                            })}
                            </div>
                        </div>
                      </div>
                    </div>
                  </div>

                {/* 
                  <select
                    className='form-select form-select-solid'
                    data-kt-select2='true'
                    data-placeholder='Select option'
                    data-allow-clear='true'
                    defaultValue={formik.values.idTemplate ? formik.values.idTemplate : '1'}
                    onChange={(e: any) => {
                      selectTemplate(e.target.value)
                      formik.setValues({...formik.values, idTemplate: e.target.value})
                      //setId(e.target.value)
                    }}
                  >
                    <option value={'1'}>Selecione um template</option>
                    {myTemplates && myTemplates.map((myTemplate: any) => {
                      return (
                        <option key={myTemplate.id} value={myTemplate.id} >
                          <div style={{display: 'flex', justifyContent: 'space-between', background: 'green'}}>
                            <span>{myTemplate.description}</span>
                            {" "}-{"  "}
                            <span 
                              className={
                                `badge badge-light-${
                                  myTemplate.type === "private" 
                                  ? "warning" 
                                  : myTemplate.type === "public" 
                                  ? "success"
                                  : 'secondary' 
                                } fs-7 fw-semibold`
                              }
                            >
                              {
                                myTemplate.type ? myTemplate.type : "Não identificado"
                              }
                            </span>
                          </div>
                        </option>
                      )
                    })}    
                  </select> */}
                  <div>
                    <button
                      type='button'
                      className='btn btn-lg btn-primary' 
                      onClick={() => {
                        if(typePage === 'HTML'){
                          previewPDF()
                        }else{
                          if(typePage === 'DOCX'){
                            previewPDFDocument()
                          }
                        }
                      }}>
                      Visualizar
                    </button>
                  </div>
                </div>
            }
        </div>
      
        {/* <div style={{height: '90%',overflowY: 'auto', padding: '1rem',}} className="border border-gray-300 border-dashed rounded">
            <div dangerouslySetInnerHTML={editorStateV2} />
        </div> */}
        
        <div style={{height: '90%',overflowY: 'auto', padding: '1rem',}} className="border border-gray-300 border-dashed rounded">
            {pdfLoading 
              ? 
                <div>Carregando...</div>
              : 
                url ? (
                  <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
                    <Viewer fileUrl={url} plugins={[newPlugin]}/>
                  </Worker>
                ) : 
                  <div
                    className='fw-bold fs-6 text-gray-400'
                    style={{
                      height: '40rem',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                  Visualize sua proposta.
                </div>
                }
        </div>
      
        <ModalDeleteTemplate 
          show={showCreateAppModalDelete}
          data={template}
          id={template ? template.id : undefined}
          myTemplate={myTemplate} 
          setMyTemplate={setMyTemplate}
          setInitialHtml={setInitialHtml}
          handleClose={() => {
            setTemplate(null)
            setShowCreateAppModalDelete(false)
            refresh()
          }} 
        />
        
    </div>
  );
}