import html2pdf from 'html2pdf.js';
import { getfileCloundDocxUrl, getfileUrl } from '../services/uploadService';


// import { url } from 'inspector';
// import jsPDF from 'jspdf';
// import html2canvas from 'html2canvas';

//---------------------------- Versão 1 --------------------------------------
// export const linkPDF = async (myHTML: string): Promise<string> => {
//   return new Promise((resolve, reject) => {
//     var pdf = new jsPDF({
//       unit: 'mm',
//       format: 'a4',
//       orientation: 'portrait',
//     });

//     pdf.html(myHTML, {
//       callback: function (pdf) {
//         // Converta o Blob do PDF em um URL
//         var pdfBlob = pdf.output("blob");
//         var url = URL.createObjectURL(pdfBlob);

//         // Resolva a promise com o URL
//         resolve(url);
//       },
//       // Você pode adicionar mais opções aqui, se necessário
//     });
//   });
// };

//---------------------------Versão 2-------------------------------------------
// export const linkPDF = async (myHTML: string) => {
//   try {
//     const parser = new DOMParser();
//     const myDocument = parser.parseFromString("<div id='report_template'>"+myHTML+"</div>", 'text/html')
//     const content = myDocument.getElementById(`report_template`);

//     const options = {
//       margin: 10,
//       filename: 'documento.pdf',
//       image: { type: 'jpeg', quality: 1.0 },
//       html2canvas: { scale: 2, useCORS: true },
//       jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' },
//     };

//     let pdfBlob = await html2pdf().set(options).from(content).toPdf().output('blob').then( (data: Blob) => {
//       return data
//     })

//     const url = URL.createObjectURL(pdfBlob);

//     return url;
//   } catch (error) {
//     throw new Error(`Erro ao gerar PDF: ${error}`);
//   }
// };
//-----------------------------------Versão 3 ---------------------------------------

// export const linkPDF = async (myHTML: string) => {
//   try {
//     const parser = new DOMParser();
//     const myDocument = parser.parseFromString("<div id='report_template'>"+myHTML+"</div>", 'text/html')
//     const content = myDocument.getElementById('report_template');

//     // Encontre todas as imagens no conteúdo
//     const images = content!.querySelectorAll('img');

//     // Pré-carregue cada imagem
//     await Promise.all(Array.from(images).map(async (img) => {
//       const src = img.getAttribute('src');
//       if (src && src.startsWith('http')) {
//         // Se a imagem for referenciada por uma URL externa, pré-carregue antes de gerar o PDF
//         await new Promise((resolve) => {
//           const image = new Image();
//           image.onload = resolve;
//           image.src = src;
//         });
//       }
//     }));

//     const options = {
//       margin: 10,
//       filename: 'documento.pdf',
//       image: { type: 'jpeg', quality: 0.98 },
//       html2canvas: { scale: 2 },
//       jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' },
//     };

//     const pdfBlob = await html2pdf().from(content).set(options).outputPdf();
//     const pdfBuffer = await pdfBlob.arrayBuffer();  // Converta o Blob para ArrayBuffer
//     const pdfData = new Uint8Array(pdfBuffer);

//     // Crie um Blob com o ArrayBuffer
//     const pdfBlobWithBuffer = new Blob([pdfData], { type: 'application/pdf' });

//     // Crie o URL do objeto usando a função createObjectURL
//     const url = URL.createObjectURL(pdfBlobWithBuffer);

//     return url;
//   } catch (error) {
//     throw new Error(`Erro ao gerar PDF: ${error}`);
//   }
// };

//----------------------------Versão 4 --------------------------------

// export const linkPDF = async (myHTML: string) => {
//   try {
//     const parser = new DOMParser();
//     const myDocument = parser.parseFromString("<div id='report_template'>"+myHTML+"</div>", 'text/html');
//     const content = myDocument.getElementById('report_template');

//     // Use html2canvas para renderizar o conteúdo em um canvas
//     const canvas = await html2canvas(content!);

//     // Converta o canvas para um Blob
//     const pdfBlob = await new Promise<Blob>((resolve) => {
//       canvas.toBlob((blob) => {
//         resolve(blob as Blob);
//       }, 'application/pdf');
//     });

//     // Crie o URL do objeto usando a função createObjectURL
//     const url = URL.createObjectURL(pdfBlob);

//     return url;
//   } catch (error) {
//     throw new Error(`Erro ao gerar PDF: ${error}`);
//   }
// };


//-------------------Versão 5 -------------------------------------
// export const linkPDF = async (myHTML: string) => {
//   try {
//     const browser = await puppeteer.launch();
//     const page = await browser.newPage();

//     // Carregue o HTML diretamente na página do Puppeteer
//     await page.setContent(myHTML);

//     // Gere o PDF a partir do conteúdo HTML
//     const pdfBuffer = await page.pdf();

//     // Feche o navegador
//     await browser.close();

//     // Crie o URL do objeto usando a função createObjectURL
//     const url = URL.createObjectURL(new Blob([pdfBuffer], { type: 'application/pdf' }));

//     return url;
//   } catch (error) {
//     throw new Error(`Erro ao gerar PDF: ${error}`);
//   }
// };

//-------------------Versão 6 ---------------------------

export const linkPDF = async (myHTML: string) => {
  try {
    const parser = new DOMParser();
    const myDocument = parser.parseFromString("<div id='report_template'>"+myHTML+"</div>", 'text/html')
    const content = myDocument.getElementById(`report_template`);

    const options = {
      margin: 3,
      filename: 'documento.pdf',
      image: { type: 'jpeg', quality: 1.0 },
      html2canvas: { scale: 2, useCORS: true, logging: true },
      jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' },
    };

    let pdfBlob = await html2pdf().set(options).from(content).toPdf().output('blob').then( (data: Blob) => {
      return data
    })

    const url = URL.createObjectURL(pdfBlob);

    return url;
  } catch (error) {
    throw new Error(`Erro ao gerar PDF: ${error}`);
  }
};


export const linkPDFV2 = async (myHTML: string) => {
  try {
    const parser = new DOMParser();
    const myDocument = parser.parseFromString("<div id='report_template'>" + myHTML + "</div>", 'text/html');
    const content = myDocument.getElementById('report_template');

    const options = {
      margin: 3,
      filename: 'documento.pdf',
      image: { type: 'jpeg', quality: 1.0 },
      html2canvas: { scale: 2, useCORS: true, logging: true },
      jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' },
    };

    const pdfBlob = await html2pdf()
      .set(options)
      .from(content)
      .toPdf()
      .output('blob');

    const formData = new FormData();
    const fileName = 'documento.pdf';
    formData.append('file', new File([pdfBlob], fileName, { type: 'application/pdf' }));

    const uploadResponse = await getfileUrl(formData);

    if (!uploadResponse || !uploadResponse.url) {
      throw new Error('Erro ao obter a URL do arquivo após o upload.');
    }

    let pdfUrl = uploadResponse.url;

    // const isWebView = () => {
    //   const userAgent = navigator.userAgent || navigator.vendor;
    //   return (
    //     /wv/.test(userAgent) ||
    //     /Android.*Version\/[0-9\.]+/.test(userAgent) ||
    //     /iPhone|iPad|iPod.*AppleWebKit(?!.*Safari)/.test(userAgent)
    //   );
    // };

    // if (isWebView()) {
    //   const url = new URL(pdfUrl);
    //   url.searchParams.append('open_external_app', 'true');
    //   pdfUrl = url.toString();
    // }

    window.open(pdfUrl, '_blank', 'noopener,noreferrer');

    return pdfUrl;
  } catch (error) {
    throw new Error(`Erro ao gerar ou salvar o PDF: ${error}`);
  }
};


// export const generatePDF = (myHTML: string, id: string) => {

//     const parser = new DOMParser();
//     const myDocument = parser.parseFromString(myHTML, 'text/html')

//     const content = myDocument.getElementById(`${id}`);
    
//     // const content = document.getElementById('report-content');
    
//     const pdfOptions = {
//       margin: 3,
//       filename: 'documento.pdf',
//       image: { type: 'jpeg', quality: 1.0 },
//       html2canvas: { scale: 2, useCORS: true, logging: true },
//       jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' },
//     };

//     const pdf = html2pdf().from(content).set(pdfOptions)

//     pdf.outputPdf(pdf => {
//       const blob = new Blob([pdf], { type: 'application/pdf' });
//       const url = URL.createObjectURL(blob);

//       const a = document.createElement('a');
//       a.href = url;
//       a.target = '_blank';
//       a.rel = 'noopener noreferrer';
//       a.click();
//     }).save()
// };





// export const generatePDF = async (myHTML: string, id: string) => {
//   const parser = new DOMParser();
//   const myDocument = parser.parseFromString(myHTML, 'text/html');
//   const content = myDocument.getElementById(id);

//   if (!content) {
//     console.error(`Elemento com ID "${id}" não encontrado no HTML fornecido.`);
//     return;
//   }

//   const pdfOptions = {
//     margin: 3,
//     filename: 'documento.pdf',
//     image: { type: 'jpeg', quality: 1.0 },
//     html2canvas: { scale: 2, useCORS: true, logging: true },
//     jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' },
//   };

//   try {
//     const pdfBlob = await html2pdf().from(content).set(pdfOptions).outputPdf('blob');

//     const reader = new FileReader();
//     reader.onloadend = () => {
//       const base64Data = reader.result?.toString();

//       if (!base64Data) {
//         console.error('Erro ao converter Blob para Base64.');
//         return;
//       }

//       const link = document.createElement('a');
//       link.href = `data:application/pdf;base64,${base64Data.split(',')[1]}`;
//       link.download = 'documento.pdf';
//       link.target = '_blank';
//       link.rel = 'noopener noreferrer';
//       link.click();
//     };

//     reader.readAsDataURL(pdfBlob);
//   } catch (error) {
//     console.error('Erro ao gerar o PDF:', error);
//   }
// };

// export const generatePDF = async (myHTML: string, id: string) => {
//   const parser = new DOMParser();
//   const myDocument = parser.parseFromString(myHTML, 'text/html');
//   const content = myDocument.getElementById(id);

//   if (!content) {
//     console.error(`Elemento com ID "${id}" não encontrado no HTML fornecido.`);
//     return;
//   }

//   const pdfOptions = {
//     margin: 3,
//     filename: 'documento.pdf',
//     image: { type: 'jpeg', quality: 1.0 },
//     html2canvas: { scale: 2, useCORS: true, logging: true },
//     jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' },
//   };

//   try {
//     const pdfBlob = await html2pdf().from(content).set(pdfOptions).outputPdf('blob');
    
//     const url = URL.createObjectURL(pdfBlob);
//     window.open(url, '_blank', 'noopener,noreferrer');

//     setTimeout(() => URL.revokeObjectURL(url), 10000);
//   } catch (error) {
//     console.error('Erro ao gerar o PDF:', error);
//   }
// };

// export const generatePDF = async (myHTML: string, id: string) => {
//   const parser = new DOMParser();
//   const myDocument = parser.parseFromString(myHTML, 'text/html');
//   const content = myDocument.getElementById(id);

//   if (!content) {
//     console.error(`Elemento com ID "${id}" não encontrado no HTML fornecido.`);
//     return;
//   }

//   const pdfOptions = {
//     margin: 3,
//     filename: 'documento.pdf',
//     image: { type: 'jpeg', quality: 1.0 },
//     html2canvas: { scale: 2, useCORS: true, logging: true },
//     jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' },
//   };

//   try {
//     const pdfBlob = await html2pdf().from(content).set(pdfOptions).outputPdf('blob');
    
//     let url = URL.createObjectURL(pdfBlob);

//     // Adiciona query string se a largura da tela for menor que 1200px
//     if (window.innerWidth < 1200) {
//       url += '?open_external_app=true';
//     }

//     window.open(url, '_blank', 'noopener,noreferrer');

//     setTimeout(() => URL.revokeObjectURL(url), 10000);
//   } catch (error) {
//     console.error('Erro ao gerar o PDF:', error);
//   }
// };

// export const generatePDF = async (myHTML: string, id: string) => {
//   const parser = new DOMParser();
//   const myDocument = parser.parseFromString(myHTML, 'text/html');
//   const content = myDocument.getElementById(id);

//   if (!content) {
//     console.error(`Elemento com ID "${id}" não encontrado no HTML fornecido.`);
//     return;
//   }

//   const pdfOptions = {
//     margin: 3,
//     filename: 'documento.pdf',
//     image: { type: 'jpeg', quality: 1.0 },
//     html2canvas: { scale: 2, useCORS: true, logging: true },
//     jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' },
//   };

//   // Função para verificar se está em um WebView
//   const isWebView = () => {
//     const userAgent = navigator.userAgent || navigator.vendor || window.opera;
//     return (
//       /wv/.test(userAgent) || // Indica WebView em Android
//       /Android.*Version\/[0-9\.]+/.test(userAgent) || // WebView padrão no Android
//       /iPhone|iPad|iPod.*AppleWebKit(?!.*Safari)/.test(userAgent) // WebView no iOS
//     );
//   };

//   try {
//     const pdfBlob = await html2pdf().from(content).set(pdfOptions).outputPdf('blob');

//     let url = URL.createObjectURL(pdfBlob);

//     // Adiciona query string apenas se estiver em WebView
//     if (isWebView()) {
//       url += '?open_external_app=true';
//     }

//     window.open(url, '_blank', 'noopener,noreferrer');

//     setTimeout(() => URL.revokeObjectURL(url), 10000);
//   } catch (error) {
//     console.error('Erro ao gerar o PDF:', error);
//   }
// };

// export const generatePDF = async (myHTML: string, id: string) => {
//   const parser = new DOMParser();
//   const myDocument = parser.parseFromString(myHTML, 'text/html');
//   const content = myDocument.getElementById(id);

//   if (!content) {
//     console.error(`Elemento com ID "${id}" não encontrado no HTML fornecido.`);
//     return;
//   }

//   const pdfOptions = {
//     margin: 3,
//     filename: 'documento.pdf',
//     image: { type: 'jpeg', quality: 1.0 },
//     html2canvas: { scale: 2, useCORS: true, logging: true },
//     jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' },
//   };

//   const isWebView = () => {
//     const userAgent = navigator.userAgent || navigator.vendor;
//     return (
//       /wv/.test(userAgent) ||
//       /Android.*Version\/[0-9\.]+/.test(userAgent) ||
//       /iPhone|iPad|iPod.*AppleWebKit(?!.*Safari)/.test(userAgent)
//     );
//   };

//   try {
//     const pdfBlob = await html2pdf().from(content).set(pdfOptions).outputPdf('blob');

//     let url = URL.createObjectURL(pdfBlob);

//     if (isWebView()) {
//       url += '?open_external_app=true';
//     }

//     window.open(url, '_blank', 'noopener,noreferrer');

//     setTimeout(() => URL.revokeObjectURL(url), 10000);
//   } catch (error) {
//     console.error('Erro ao gerar o PDF:', error);
//   }
// };

export const generatePDF = async (myHTML: string, id: string, currentUser?: any) => {
  const parser = new DOMParser();
  const myDocument = parser.parseFromString(myHTML, 'text/html');
  const content = myDocument.getElementById(id);

  if (!content) {
    console.error(`Elemento com ID "${id}" não encontrado no HTML fornecido.`);
    return;
  }

  const pdfOptions = {
    margin: 3,
    filename: 'documento.pdf',
    image: { type: 'jpeg', quality: 1.0 },
    html2canvas: { scale: 2, useCORS: true, logging: true },
    jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' },
  };

  // Função para detectar se o site está sendo acessado via WebView
  const isWebView = () => {
    const userAgent = navigator.userAgent || navigator.vendor;
    return (
      /wv/.test(userAgent) || // WebView em Android
      /Android.*Version\/[0-9\.]+/.test(userAgent) || // Android WebView
      /iPhone|iPad|iPod.*AppleWebKit(?!.*Safari)/.test(userAgent) // iOS WebView
    );
  };

  try {
    // Gerar o PDF como Blob
    const pdfBlob = await html2pdf().from(content).set(pdfOptions).outputPdf('blob');

    // Criar o arquivo FormData para envio
    const formData = new FormData();
    const fileName = 'documento.pdf';
    formData.append('file', new File([pdfBlob], fileName, { type: 'application/pdf' }));

    // Enviar o arquivo usando a função getfileUrl
    const uploadResponse = await getfileUrl(formData);
    
    // const uploadResponse = await getfileCloundDocxUrl(
    //   formData, 
    //   currentUser 
    //   && currentUser.companies 
    //   && currentUser.companies.length > 0 
    //   && currentUser.companies[0].corporateName
    //   ? currentUser.companies[0].corporateName
    //   : "", 
    //   "report-temp",
    //   "pdf"
    // )

    if (uploadResponse && uploadResponse.url) {
      let pdfUrl = uploadResponse.url;

      // Adicionar 'open_external_app=true' na query string se estiver em WebView
      if (isWebView()) {
        const url = new URL(pdfUrl);
        url.searchParams.append('open_external_app', 'true');
        pdfUrl = url.toString();
      }

      // Abrir a URL do PDF
      window.open(pdfUrl, '_blank', 'noopener,noreferrer');
    } else {
      console.error('Erro ao obter a URL do arquivo após o upload.');
    }
  } catch (error) {
    console.error('Erro ao gerar ou salvar o PDF:', error);
  }
};




// export const generatePDF = async (myHTML: string, id: string) => {
//   const parser = new DOMParser();
//   const myDocument = parser.parseFromString(myHTML, 'text/html');
//   const content = myDocument.getElementById(`${id}`);

//   const pdfOptions = {
//     margin: 3,
//     filename: 'documento.pdf',
//     image: { type: 'jpeg', quality: 1.0 },
//     html2canvas: { scale: 2, useCORS: true, logging: true },
//     jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' },
//   };

//   const pdf = await html2pdf().from(content).set(pdfOptions).outputPdf();

//   const blob = new Blob([pdf], { type: 'application/pdf' });

//   const reader = new FileReader();
//   reader.onloadend = () => {
//     const base64Data = reader.result?.toString();

//     // Abra o PDF em uma nova aba ou no WebView
//     const link = document.createElement('a');
//     link.href = `data:application/pdf;base64,${base64Data?.split(',')[1]}`;
//     link.download = 'documento.pdf';
//     link.target = '_blank';
//     link.rel = 'noopener noreferrer';
//     link.click();
//   };
//   reader.readAsDataURL(blob);
// };






// export const generatePDF = async (myHTML: string, id: string) => {
//   const parser = new DOMParser();
//   const myDocument = parser.parseFromString(myHTML, 'text/html');
//   const content = myDocument.getElementById(`${id}`);

//   const pdfOptions = {
//     margin: 3,
//     image: { type: 'jpeg', quality: 1.0 },
//     html2canvas: { scale: 2, useCORS: true },
//     jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' },
//   };

//   const pdf = await html2pdf().from(content).set(pdfOptions).outputPdf();

//   const isBrowser = typeof window !== 'undefined' && typeof window.document !== 'undefined';

//   if (isBrowser) {
//     const blob = new Blob([pdf], { type: 'application/pdf' });
//     const url = URL.createObjectURL(blob);

//     const a = document.createElement('a');
//     a.href = url;
//     a.download = 'documento.pdf';
//     a.click();
//     URL.revokeObjectURL(url);
//   } else {
//     const path = `${RNFS.DocumentDirectoryPath}/documento.pdf`;
//     await RNFS.writeFile(path, pdf, 'base64');
//     console.log(`PDF salvo em: ${path}`);
//   }
// };  

export const generatePDFBase64 = async (myHTML: string, id: string) => {
    try {
      const parser = new DOMParser();
      const myDocument = parser.parseFromString("<div id='report_template'>"+myHTML+"</div>", 'text/html');
      const content = myDocument.getElementById(`${id}`);
  
      const options = {
        margin: 3,
        filename: 'documento.pdf',
        image: { type: 'jpeg', quality: 1.0 },
        html2canvas: { scale: 2, useCORS: true, logging: true },
        jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' },
      };
  
      let pdfBlob = await html2pdf().set(options).from(content).toPdf().output('blob');
  
      const base64String = await blobToBase64(pdfBlob);

      return base64String;
    } catch (error) {
      return ""
    }
  };

  const blobToBase64 = (blob: Blob): Promise<string> => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => {
        const base64String = (reader.result as string).split(',')[1];
        resolve(base64String);
      };
      reader.onerror = reject;
      reader.readAsDataURL(blob);
    });
  };

  // export const generatePDFBase64 = (myHTML: string, id: string) => {
  //   const parser = new DOMParser();
  //   const myDocument = parser.parseFromString(myHTML, 'text/html');
  //   const content = myDocument.getElementById(`${id}`);
  
  //   if (!content) {
  //     console.error(`Elemento com ID ${id} não encontrado no HTML.`);
  //     return Promise.reject(new Error(`Elemento com ID ${id} não encontrado.`));
  //   }
  
  //   const pdfOptions = {
  //     margin: 3,
  //     filename: 'documento.pdf',
  //     image: { type: 'jpeg', quality: 1.0 },
  //     html2canvas: { scale: 2, useCORS: true, logging: true },
  //     jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' },
  //   };
  
  //   console.log('Iniciando geração do PDF...');
    
  //   return new Promise((resolve, reject) => {
  //     html2pdf()
  //       .from(content)
  //       .set(pdfOptions)
  //       .outputPdf((pdf) => {
  //         console.log('PDF gerado com sucesso');
          
  //         const base64String = btoa(
  //           new Uint8Array(pdf).reduce((data, byte) => data + String.fromCharCode(byte), '')
  //         );
  
  //         console.log('Base64 gerado:', base64String);
  //         resolve(base64String);
  //       })
  //       .catch((err) => {
  //         console.error('Erro ao gerar PDF:', err);
  //         reject(err);
  //       });
  //   });
  // };


  // export const generatePDFBase64 = (myHTML: string, id: string) => {
  //   const parser = new DOMParser();
  //   const myDocument = parser.parseFromString(myHTML, 'text/html');
  //   const content = myDocument.getElementById(`${id}`);
  
  //   const pdfOptions = {
  //     margin: 3,
  //     filename: 'documento.pdf',
  //     image: { type: 'jpeg', quality: 1.0 },
  //     html2canvas: { scale: 2, useCORS: true, logging: true },
  //     jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' },
  //   };
  
  //   return new Promise((resolve, reject) => {
  //     html2pdf()
  //       .from(content)
  //       .set(pdfOptions)
  //       .outputPdf((pdf) => {
  //         const base64String = btoa(pdf); // Converte o PDF para base64
  //         resolve(base64String);
  //       })
  //       .catch((err) => reject(err));
  //   });
  // };