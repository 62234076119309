/* eslint-disable react-hooks/exhaustive-deps */
import clsx from 'clsx'
import {Link} from 'react-router-dom'
import { useAuth } from '../../../../app/modules/auth'
import {KTSVG, toAbsoluteUrl} from '../../../helpers'
import {useLayout} from '../../core'
//import {Header} from './Header'
import {Navbar} from './Navbar'
import './styles.css'
import useSize from '../../../../app/hooks/useSize'
import { useEffect, useState } from 'react'
import { getFile } from '../../../../app/services/files'

export function HeaderWrapper({lag, searchNotificationsDrawer} : any) {
  const {config, classes} = useLayout()
  const {currentUser} = useAuth()
  const {width} = useSize()
  const [imgUrl, setImgUrl] = useState<string | null>(null);

  if (!config.app?.header?.display) {
    return null
  }

  const getImage = async (key: string) => {
    try {
      //setLoadingImgGet(true)
      const url = await getFile(key)
      setImgUrl(url.url)
      //formik.setValues({...formik.values, image: url.url})

    } catch (error) {
      console.log(error)
      //toggleToast("Erro ao buscar a imagem. ", "error")

    }finally{
      //setLoadingImgGet(false)
    }
  }

  // eslint-disable-next-line react-hooks/rules-of-hooks
  useEffect(() => {
    if(currentUser 
      && currentUser.companies 
      && currentUser.companies.length > 0 
      && currentUser.companies[0].image){
        if(width < 1200){
          getImage(currentUser.companies[0].image_cell)
        }
    }
  }, [])

  //

  return (
    <div id='kt_app_header' className={`${width < 1200 ? '' : 'app-header'} completeTopBar bg-${width < 1200 ? 'primary' : ''}`} 
      style={lag && lag < 1200 ?{
        position: 'fixed', 
        width: '100%',
        zIndex: '10',
        paddingTop: '4.5rem',
        height: '8rem',
        paddingBottom: '5rem',
      } : {}}>
      <div
        id='kt_app_header_container'
        className={clsx(
          'app-container flex-lg-grow-1',
          classes.headerContainer.join(' '),
          config.app?.header?.default?.containerClass
        )}
        style={{width: '100%'}}
      >

        {config.app.sidebar?.display && (
          <>
            <div
              className='d-flex align-items-center d-lg-none ms-n2 me-2'
              title='Show sidebar menu'
            >
              <div
                className='btn btn-icon btn-active-color-primary w-35px h-35px'
                id='kt_app_sidebar_mobile_toggle'
              >
                <KTSVG path='/media/icons/duotune/abstract/abs015.svg' className=' svg-icon-1' />
              </div>
              
              <div className='d-flex align-items-center flex-grow-1 flex-lg-grow-0'>
                  {
                    imgUrl ?
                    (
                      <Link to='/dashboard' className='d-lg-none'>
                        <img
                          alt="Logo"
                          src={config.layoutType !== 'dark-header' ? toAbsoluteUrl(imgUrl) : toAbsoluteUrl(imgUrl)}
                          className="w-150px max-h-40px"
                          style={{
                            maxHeight: '40px',
                            width: 'auto',
                            objectFit: 'cover',
                            overflow: 'hidden'
                          }}
                        />
                      </Link>
                    )
                    :
                    (
                      <Link to='/dashboard' className='d-lg-none'>
                        <img
                          alt='Logo'
                          src={config.layoutType !== 'dark-header' ? toAbsoluteUrl('/media/imgs/sua-logo-preta.png') :  toAbsoluteUrl('/media/imgs/sua-logo-aqui.png')}
                          className='h-45px'
                        />
                      </Link>
                    )
                  }
                  {/* <Link to='/dashboard' className='d-lg-none'>
                    <img
                      alt='Logo'
                      src={config.layoutType !== 'dark-header' ? toAbsoluteUrl('/media/imgs/sublogo-light.png') :  toAbsoluteUrl('media/imgs/sublogo.png')}
                      className='h-45px'
                    />
                  </Link> */}
                </div>
              {/* {
                config.layoutType !== 'dark-header' ? 
                (
                  <div className='d-flex align-items-center flex-grow-1 flex-lg-grow-0'>
                    <Link to='/dashboard' className='d-lg-none'>
                      <img
                        alt='Logo'
                        src={toAbsoluteUrl('/media/imgs/sublogo-light.png')}
                        className='h-45px'
                      />
                    </Link>
                  </div>
                )
                :
                (
                  <div className='d-flex align-items-center flex-grow-1 flex-lg-grow-0'>
                    <Link to='/dashboard' className='d-lg-none'>
                      <img
                        alt='Logo'
                        src={toAbsoluteUrl('/media/imgs/sublogo.png')}
                        className='h-45px'
                      />
                    </Link>
                  </div>
                )
              } */}
            </div>
          </>
        )}

        {
        //   currentUser && currentUser.companies && currentUser.companies.length > 0 && currentUser.companies[0].image ?
        //     <div className='d-flex align-items-center flex-grow-1 flex-lg-grow-0 me-lg-15'>
        //       <Link to='/dashboard'>
        //           <img
        //             alt='Logo'
        //             src={toAbsoluteUrl(currentUser.companies[0].image)}
        //             className='h-20px h-lg-30px app-sidebar-logo-default'
        //           />
        //       </Link>
        //     </div>
        // :
        // !config.app.sidebar?.display && (
        //   <div className='d-flex align-items-center flex-grow-1 flex-lg-grow-0 me-lg-15'>
        //     <Link to='/dashboard'>
        //       {config.layoutType !== 'dark-header' ? (
        //         <img
        //           alt='Logo'
        //           src={toAbsoluteUrl('/media/imgs/sua-logo-aqui.png')}
        //           className='h-20px h-lg-30px app-sidebar-logo-default'
        //         />
        //       ) : (
        //         <>
        //           <img
        //             alt='Logo'
        //             src={toAbsoluteUrl('/media/imgs/sua-logo-preta.png')}
        //             className='h-20px h-lg-30px app-sidebar-logo-default theme-light-show'
        //           />
        //           <img
        //             alt='Logo'
        //             src={toAbsoluteUrl('/media/imgs/sua-logo-aqui.png')}
        //             className='h-20px h-lg-30px app-sidebar-logo-default theme-dark-show'
        //           />
        //         </>
        //       )}
        //     </Link>
        //   </div>
        // )
        
        }
        <div
          id='kt_app_header_wrapper'
          className='d-flex align-items-stretch justify-content-between flex-lg-grow-1'
        >
          {config.app.header.default?.content === 'menu' &&
            config.app.header.default.menu?.display && (
              <div
                className='app-header-menu app-header-mobile-drawer align-items-stretch'
                data-kt-drawer='true'
                data-kt-drawer-name='app-header-menu'
                data-kt-drawer-activate='{default: true, lg: false}'
                data-kt-drawer-overlay='true'
                data-kt-drawer-width='225px'
                data-kt-drawer-direction='end'
                data-kt-drawer-toggle='#kt_app_header_menu_toggle'
                data-kt-swapper='true'
                data-kt-swapper-mode="{default: 'append', lg: 'prepend'}"
                data-kt-swapper-parent="{default: '#kt_app_body', lg: '#kt_app_header_wrapper'}"
              >
                {/* <Header /> */}
              </div>
            )}
          <Navbar searchNotificationsDrawer={searchNotificationsDrawer}/>
        </div>
      </div>
    </div>
  )
}
