/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useRef, useState} from 'react'
import ApexCharts, {ApexOptions} from 'apexcharts'

import {getCSS, getCSSVariableValue} from '../../../../../../_metronic/assets/ts/_utils'
import {useThemeMode} from '../../../../../../_metronic/partials/layout/theme-mode/ThemeModeProvider'

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css"; 
import { ptBR } from "date-fns/locale";
import { KTSVG } from '../../../../../../_metronic/helpers'
import { LoadingSpan } from '../../../Loadings/loadingSpan'
import { getShortMonthNameString } from '../../../../../utils/date';
import useSize from '../../../../../hooks/useSize';
//import './styles.css'

type Props = {
  className: string;
  charts: any;
  prognostico?: any
  myProg?: any
}

const BarGraphInterval: React.FC<Props> = ({className, charts, prognostico, myProg}) => {
  const chartRef = useRef<HTMLDivElement | null>(null)
  const {mode} = useThemeMode()
  const [listDates, setListDates] = useState<any>([]);
  const [myPrognostics, setMyPrognostics] = useState<any>(null);
  const [selectedValue, setSelectedValue] = useState<any>();
  const [chartsValues, setChartsValues] = useState({
    metaDates: [],
    valores: [],
    type: "" 
  });

  const [startDate, setStartDate] = useState<Date | null>(null); // Data inicial
  const [endDate, setEndDate] = useState<Date | null>(null); // Data final

  const [dateAntigaMetaDates, setDateAntigaMetaDates] = useState<string>("");
  const [dateAtualMetaDates, setDateAtualMetaDates] = useState<string>(""); 

  const [generation, setGeneration] = useState<string>("0"); 
  const [desempenho, setDesempenho] = useState<string>("0"); 

  const {width} = useSize()


  const handleChange = ([newStartDate, newEndDate]) => {
    setStartDate(newStartDate);
    setEndDate(newEndDate);
  };

  function getDaysInMonth(year, month) {
    return new Date(year, month, 0).getDate();
}


  const handleChartDeploy = () => {
    if (charts && Object.keys(charts).length > 0) {
      const myMetaDates = charts["type"] && charts["type"] === "interval_charts" ? charts["dias"] : [];
      const type = charts["type"] ? charts["type"] : "";
      const myValores = (charts["type"] && (charts["type"] === "total_charts" || charts["type"] === "interval_charts"))
        ? charts["valores"]
        : [];
  
      if (myMetaDates.length !== myValores.length) {
        //console.warn("Inconsistência nos dados: 'dias' e 'valores' têm comprimentos diferentes.");
        return;
      }

      if(myMetaDates.length > 0){
        const mostRecentDate = myMetaDates.reduce((a, b) => (a > b ? a : b));
        const oldestDate = myMetaDates.reduce((a, b) => (a < b ? a : b));

        setDateAntigaMetaDates(oldestDate)
        setDateAtualMetaDates(mostRecentDate)
      }
  
      const filteredData = myMetaDates.map((date, index) => ({
        data: date,
        valor: myValores[index],
      })).filter(entry => {
        const date = new Date(entry.data);
        return date >= new Date(new Date(startDate ? startDate : '').setDate(new Date(startDate ? startDate : '').getDate() - 1)) && date <= new Date(new Date(endDate ? endDate : '').setDate(new Date(endDate ? endDate : '').getDate() - 1));
      });
  
      const filteredDates = filteredData.map(entry => entry.data);
      const filteredValores = filteredData.map(entry => entry.valor);

      const myGeneration = filteredValores.reduce((acumulador: number, valorAtual: number) => acumulador + valorAtual, 0);
      setGeneration(myGeneration ? myGeneration.toFixed(2).toString() : "0")
  

      if(myProg){
        
        const uniqueMonths = [...new Set(filteredDates.map(date => {
          if (typeof date === 'string') {
              return date.slice(0, 7);
          }
          return '';
        }))];
      
        const prognosticValues = uniqueMonths.map(monthString => {
            if (typeof monthString !== 'string' || monthString === '') return 0;
            const value = getShortMonthNameString(monthString);
            const valueProp = `prog_${value}`;
            const valueFinal = myProg[valueProp] || 0;
            
            const [year, month] = monthString.split('-');
            const daysInMonth = getDaysInMonth(parseInt(year, 10), parseInt(month, 10));
            
            return parseFloat((valueFinal / daysInMonth).toFixed(2));
        });
        
        const finalArray = filteredDates.map(date => {
            if (typeof date !== 'string') return 0;
            const monthString = date.slice(0, 7);
            const index = uniqueMonths.indexOf(monthString);
            return prognosticValues[index] || 0;
        });
      
        
        setMyPrognostics(finalArray);

        const somaTotalPrognostico = finalArray.reduce((acumulador, valorAtual) => acumulador + valorAtual, 0);
        
        const porcentagem_prognostico = (myGeneration / somaTotalPrognostico) * 100

        setDesempenho(porcentagem_prognostico && porcentagem_prognostico !== Infinity ? porcentagem_prognostico.toFixed(2).toString() : "0")
      }

      setChartsValues({
        metaDates: filteredDates,
        valores: filteredValores,
        type: type 
      });
    } else {
      //console.warn("Objeto 'charts' está vazio ou indefinido.");
    }
  };

  
  // useEffect(() => {
  //   handleChartDeploy()
  // }, [selectedValue])

  useEffect(() => {
    handleChartDeploy()
  }, [charts, startDate, endDate])
  
  useEffect(() => {
      // if(charts){
      //   const string_date : string = Object.keys(charts)[0]
      //   setSelectedValue(string_date)
      //   const list = Object.entries(charts);
      //   setListDates(list)
      // }

      if(charts && Object.keys(charts).length > 0){
        
        const myMetaDates = charts["type"] && charts["type"] === "interval_charts" ? charts["dias"] : [];
        const mostRecentDate = myMetaDates.length > 0 ? myMetaDates.reduce((a : string, b: string) => (a > b ? a : b)) : new Date().toDateString();

        const date_init = (() => {
          const today = new Date(new Date(mostRecentDate ? mostRecentDate : '').setDate(new Date(mostRecentDate ? mostRecentDate : '').getDate() + 1));
          const date = new Date(new Date(mostRecentDate ? mostRecentDate : '').setDate(new Date(mostRecentDate ? mostRecentDate : '').getDate() + 1));
          date.setDate(today.getDate() - 7);
          return date;
        })();

        //const date_end = new Date(mostRecentDate);
        const date_end = new Date(new Date(mostRecentDate ? mostRecentDate : '').setDate(new Date(mostRecentDate ? mostRecentDate : '').getDate() + 1))

        setStartDate(date_init);
        setEndDate(date_end)
      }

  }, [charts])


  useEffect(() => {
    const chart = refreshChart()

    return () => {
      if (chart) {
        chart.destroy()
      }
    }
  }, [chartRef, mode, chartsValues])

  const refreshChart = () => {
    if (!chartRef.current) {
      return
    }

    const height = parseInt(getCSS(chartRef.current, 'height'))

    const chart = new ApexCharts(chartRef.current, getChartOptions(height, chartsValues.valores, chartsValues.metaDates, chartsValues.type, myPrognostics ? myPrognostics : null))
    if (chart) {
      chart.render()
    }
    return chart
  }


  if (!charts) {
    return (
      <div style={{width: '100%', display: 'flex', justifyContent: 'center', height: '15rem', alignItems: 'center'}}>
        <LoadingSpan style={{marginLeft: '0.5rem'}}/>
      </div>
    )
  }



  return (
    <div className={`${width < 1200 ? '' : 'card'} ${className}`}>
      {/* {charts["type"] !== "total_charts" && charts["type"] !== "interval_charts" && (
        <div className='card-header border-0 pt-1'>
          <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bold fs-3 mb-1'>{selectedValue}</span>
          </h3>
          <div className='card-toolbar' data-kt-buttons='true'>
            <div className='d-flex align-items-center my-2'>
              <div className='w-130px me-5'>
                <select
                  name='status'
                  data-control='select2'
                  data-hide-search='true'
                  className='form-select form-select-white form-select-sm'
                  value={selectedValue}
                  onChange={handleSelectChange}
                >
                  {
                    listDates.map((chart: any) => {
                      return (
                        <option value={chart[0]}>{chart[0]}</option>
                      );
                    })
                  }
                </select>
              </div>
            </div>
          </div>
        </div>
      )} */}

      <div className='card-header border-0 pt-1' style={{alignItems: 'center'}}>

        <div style={{display: 'flex', gap: '0.5rem', alignItems: 'center', marginBottom: width < 1200 ? '1rem' : '0rem', marginTop: width < 1200 ? '1rem' : '0rem'}}>
          <div>
            <KTSVG
              path='/media/icons/duotune/graphs/gra005.svg'
              className='svg-icon-3x ms-2 me-0'
            />
          </div>
          <div>
            <h3 className='card-title align-items-start flex-column'>
              <span className='card-label fw-bold fs-7 mb-0'><span className='text-muted'>Gerado -</span> {generation} KWh</span>
            </h3>
            <h3 className='card-title align-items-start flex-column'>
              <span className='card-label fw-bold fs-7 mb-0'><span className='text-muted'>Desempenho -</span>  {desempenho}%</span>
            </h3>
          </div>
        </div>

        <div>
          <DatePicker
            showIcon
            isClearable
            className="form-control form-control-sm"
            selected={startDate}
            onChange={handleChange}
            selectsRange
            showPreviousMonths
            startDate={startDate}
            endDate={endDate}
            locale={ptBR}
            //minDate={startDate}
            dateFormat="dd/MM/yyyy"
            minDate={new Date(new Date(dateAntigaMetaDates ? dateAntigaMetaDates : '').setDate(new Date(dateAntigaMetaDates ? dateAntigaMetaDates : '').getDate() + 1))}
            //minDate={new Date(new Date(dateAntigaMetaDates ? dateAntigaMetaDates : '').setDate(new Date(dateAntigaMetaDates ? dateAntigaMetaDates : '').getDate() + 1))}
            maxDate={new Date(new Date(dateAtualMetaDates ? dateAtualMetaDates : '').setDate(new Date(dateAtualMetaDates ? dateAtualMetaDates : '').getDate() + 1))}
            // dayClassName={date => 
            //   (date.getTime() === (startDate?.getTime() || 0) || date.getTime() === (endDate?.getTime() || 0)) 
            //     ? "customSelectedDay" 
            //     : undefined
            // }
          />
        </div>
      </div>

      <div className='card-body' style={{overflowX: "auto"}}>
        <div ref={chartRef} id='kt_charts_widget_1_chart' style={{height: '350px', width: '100%'}} />
      </div>
    </div>
  )
}

export {BarGraphInterval}

function getChartOptions(height: number, valores: any, metaDates: any, type: any, prognostico?: any): ApexOptions {
  const labelColor = getCSSVariableValue('--bs-gray-500')
  const borderColor = getCSSVariableValue('--bs-gray-200')
  const baseColor = getCSSVariableValue('--bs-primary')
  const secondaryColor = getCSSVariableValue('--bs-gray-300')

  return {
    series: [
      {
        name: 'Energia Gerada',
        type: 'bar',
        data: valores,
      },
      {
        name: 'Prognóstico',
        type: 'line',
        // data: Array(metaDates.length).fill(prognostico ? 
        //   type === "mounth_charts" ? parseFloat(((prognostico)/30).toFixed(2)) : 
        //   type === "year_charts" ? parseFloat(((prognostico/12)).toFixed(2)) :
        //   prognostico 
        // : 0) 
        data: prognostico ? prognostico : []
      }
    ],
    chart: {
      fontFamily: 'inherit',
      type: 'bar',
      height: height,
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: type === "mounth_charts" ? '50%' : type === "year_charts" ? "30%" : "20%",
        borderRadius: 5,
      },
    },
    legend: {
      show: false,
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      show: true,
      width: [0, 3],
      colors: ['transparent', prognostico ?  '#f1416c' : 'labelColor'],  // Cor para a linha
      curve: 'smooth'
    },
    xaxis: {
      categories: metaDates,
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      labels: {
        style: {
          colors: labelColor,
          fontSize: '12px',
        },
      },
    },
    yaxis: [
      {
        min: 0,
        seriesName: 'Energia Gerada',
        axisTicks: {
          show: true,
        },
        axisBorder: {
          show: true,
          color: baseColor
        },
        labels: {
          style: {
            colors: baseColor,
          }
        },
        title: {
          text: "Geração (KWh)",
          style: {
            color: baseColor,
          }
        },
        tooltip: {
          enabled: true
        }
      },
    ],
    states: {
      normal: {
        filter: {
          type: 'none',
          value: 0,
        },
      },
      hover: {
        filter: {
          type: 'none',
          value: 0,
        },
      },
      active: {
        allowMultipleDataPointsSelection: false,
        filter: {
          type: 'none',
          value: 0,
        },
      },
    },
    tooltip: {
      enabled: true,
      shared: true,
      intersect: false,
      style: {
        fontSize: '12px',
      },
      y: {
        formatter: function (val, { series, seriesIndex, dataPointIndex, w }) {
          if (seriesIndex === 1) {
            return val + ' KWh (Prognóstico)';
          }
          return val + ' KWh';
        },
      },
    },
    colors: [baseColor, secondaryColor],
    grid: {
      borderColor: borderColor,
      strokeDashArray: 4,
      yaxis: {
        lines: {
          show: true,
        },
      },
    },
  };
}



