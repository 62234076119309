import api from "./api";

export const getfileUrl = async (file: any) => {
    const response = await api.post(`uploads/`, file, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
    return response.data;
};

export const getfileCloundUrl = async (file: any, pasta?: string) => {
  const response = await api.post(`uploads/uploadClound/?pasta=${pasta ? pasta : ""}`, file, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  });
  return response.data;
};

export const getfileCloundDocxUrl = async (file: any, pasta?: string, nameArquive?: string, format?: string) => {
  const response = await api.post(`uploads/uploadCloundDocx/?pasta=${pasta ? pasta : ""}&nameArquive=${nameArquive ? nameArquive : ""}&format=${format ? format : ""}`, file, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  });
  return response.data;
};

export const getfileBase64 = async (file: any) => {
  const response = await api.post(`uploads/base64/`, file, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  });
  return response.data;
};



