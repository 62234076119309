import React, { useEffect, useRef, useState } from 'react';
import { KTSVG } from '../../../../../../../../../_metronic/helpers';
import { LoadingSpan } from '../../../../../../../components/Loadings/loadingSpan';

import JSZip from "jszip";
import { saveAs } from "file-saver";
import { formatarData } from '../../../../../../../../utils/date';
import { postConvertPdf } from '../../../../../../../../services/ConvertPDFService';
import { useFormik } from 'formik';
import * as Yup from "yup";
import { getfileCloundDocxUrl } from '../../../../../../../../services/uploadService';
import { useAuth } from '../../../../../../../../modules/auth';
import { postTemplateProposalOne } from '../../../../../../../../services/templatesProposal';
import { useToast } from '../../../../../../../components/Toats';
import { getUnitBranchAll } from '../../../../../../../../services/unitBranchService';

type Props = {
    formik: any;
    setLinkPDF: any;
    initialHtml: any;
    setMyTemplate: any;
    mySelectTemplate: any
    myTemplates: any;
    refresh: any
}


const validationSchema = Yup.object().shape({
  textHTML: Yup.string(),
  description: Yup.string(),
  type: Yup.string(),
  //type_template:  Yup.string(),
  idUnitBranch: Yup.string()
});

export function DocumentLayout({formik, setLinkPDF, initialHtml, setMyTemplate, mySelectTemplate, myTemplates, refresh}: Props) {
    const [loadingUploadWord, setLoadingUploadWord] = useState(false)
    const [textArquive, setTextArquive] = useState<string>("Escolha um arquivo (.docx).");
    const fileInputRef : any = useRef(null);
    const [generateTemplate, setGenerateTemplate] = useState(false);
    const [loading, setLoading] = useState(false)
    const [loadingUnitBranchs, setLoadingUnitBranchs] = useState(false)
    const [unitBranchs, setUnitBranchs] = useState<any[]>([]);
    const user = useAuth()
    const { toggleToast } = useToast();

    const [initialValues] = useState({
      textHTML: initialHtml,
      description: null,
      type: "private",
      typeDoc: "docx",
      titleDocx: null,
      linkDocx: null,
      idUnitBranch : null
    })

    const formikTemplate = useFormik({
      initialValues,
      validationSchema,
      validateOnChange: false,
      onSubmit: () => {},
    });
  
    const handleButtonClick = () => {
      fileInputRef.current.click();
    };

    // const processDocx = async (file, placeholders) => {
    //     try {
    //       const arrayBuffer = await file.arrayBuffer();
      
    //       const zip: any = await JSZip.loadAsync(arrayBuffer);
      
    //       const documentXml = await zip.file("word/document.xml").async("string");
      
    //       let updatedXml = documentXml;
    //       for (const [placeholder, value] of Object.entries(placeholders)) {
    //         const regex = new RegExp(`{{${placeholder}}}`, "g");
    //         updatedXml = updatedXml.replace(regex, value);
    //       }
      
    //       zip.file("word/document.xml", updatedXml);
      
    //       const updatedDocx = await zip.generateAsync({ type: "blob" });
      
    //       saveAs(updatedDocx, "processed-document.docx");
    //       console.log("Documento processado com sucesso!");
    //     } catch (error) {
    //       console.error("Erro ao processar o documento:", error);
    //     }
    //   };

    // const processDocx = async (file, placeholders) => {
    //   try {
    //     const arrayBuffer = await file.arrayBuffer();
    //     const zip: any = await JSZip.loadAsync(arrayBuffer);
    
    //     const documentXml = await zip.file("word/document.xml").async("string");
    //     let updatedXml = documentXml;
    
    //     for (const [placeholder, value] of Object.entries(placeholders)) {
    //       const regex = new RegExp(`{{${placeholder}}}`, "g");
    //       updatedXml = updatedXml.replace(regex, value);
    //     }

    //     zip.file("word/document.xml", updatedXml);
    
    //     const updatedDocx = await zip.generateAsync({ type: "blob" });
    
    //     const formData = new FormData();
    //     formData.append("file", updatedDocx, "processed-document.docx");
    
    //     const response = await postConvertPdf(formData)
    //     // const response = await axios.post("/api/convert-docx-to-pdf", formData, {
    //     //   headers: { "Content-Type": "multipart/form-data" },
    //     //   responseType: "blob", // Receber o PDF como blob
    //     // });

    //     saveAs(response.data, "processed-document.pdf");
    //     console.log("Documento processado e convertido para PDF com sucesso!");
    //   } catch (error) {
    //     console.error("Erro ao processar o documento:", error);
    //   }
    // };

    // const processDocx = async (file, placeholders) => {
    //   try {
    //     const arrayBuffer = await file.arrayBuffer();
    //     const zip: any = await JSZip.loadAsync(arrayBuffer);
    
    //     const documentXml = await zip.file("word/document.xml").async("string");
    //     let updatedXml = documentXml;
    
    //     for (const [placeholder, value] of Object.entries(placeholders)) {
    //       const regex = new RegExp(`{{${placeholder}}}`, "g");
    //       updatedXml = updatedXml.replace(regex, value);
    //     }
    
    //     zip.file("word/document.xml", updatedXml);
    
    //     const updatedDocx = await zip.generateAsync({ type: "blob" });
    
    //     const formData = new FormData();
    //     formData.append("file", updatedDocx, "processed-document.docx");
    
    //     const response = await postConvertPdf(formData);
    
    //     const pdfBlob = new Blob([response.data], { type: "application/pdf" });
    //     const url = URL.createObjectURL(pdfBlob);
    
    //     setLinkPDF(url);
    
    //     console.log("Documento processado e convertido para PDF com sucesso!");
    //   } catch (error) {
    //     console.error("Erro ao processar o documento:", error);
    //   }
    // };

    // const processDocx = async (file, placeholders) => {
    //   try {
    //     const arrayBuffer = await file.arrayBuffer();
    //     const zip: any = await JSZip.loadAsync(arrayBuffer);
    
    //     const documentXml = await zip.file("word/document.xml").async("string");
    //     let updatedXml = documentXml;
    
    //     for (const [placeholder, value] of Object.entries(placeholders)) {
    //       const regex = new RegExp(`{{${placeholder}}}`, "g");
    //       updatedXml = updatedXml.replace(regex, value);
    //     }
    
    //     zip.file("word/document.xml", updatedXml);
    //     const updatedDocx = await zip.generateAsync({ type: "blob" });
    
    //     const formData = new FormData();
    //     formData.append("file", updatedDocx, "processed-document.docx");
    //     const cloudResponse = await getfileCloundDocxUrl(formData, "", "");

    //     console.log("URL do arquivo salvo na nuvem:", cloudResponse.data.url);
    
    //     const response = await postConvertPdf(formData);
    //     const pdfBlob = new Blob([response.data], { type: "application/pdf" });
    //     const url = URL.createObjectURL(pdfBlob);
    
    //     setLinkPDF(url);
    //     console.log("Documento processado e convertido para PDF com sucesso!");
    //   } catch (error) {
    //     console.error("Erro ao processar o documento:", error);
    //   }
    // };

    const processDocx = async (file, placeholders) => {
      try {
        const formData = new FormData();
        formData.append("file", file, "original-document.docx");
        const cloudResponse = await getfileCloundDocxUrl(formData);
    
        console.log("URL do arquivo salvo na nuvem:", cloudResponse.data.url);
        formikTemplate.setValues({...formikTemplate.values, linkDocx: cloudResponse && cloudResponse.data && cloudResponse.data.url ? cloudResponse.data.url : ""})

        const arrayBuffer = await file.arrayBuffer();
        const zip: any = await JSZip.loadAsync(arrayBuffer);
    
        const documentXml = await zip.file("word/document.xml").async("string");
        let updatedXml = documentXml;
    
        for (const [placeholder, value] of Object.entries(placeholders)) {
          const regex = new RegExp(`{{${placeholder}}}`, "g");
          updatedXml = updatedXml.replace(regex, value);
        }

        zip.file("word/document.xml", updatedXml);
        const updatedDocx = await zip.generateAsync({ type: "blob" });
    
        const pdfFormData = new FormData();
        pdfFormData.append("file", updatedDocx, "processed-document.docx");
    
        const response = await postConvertPdf(pdfFormData);
        const pdfBlob = new Blob([response.data], { type: "application/pdf" });
        const url = URL.createObjectURL(pdfBlob);
    
        setLinkPDF(url);
        console.log("Documento processado e convertido para PDF com sucesso!");
      } catch (error) {
        console.error("Erro ao processar o documento:", error);
      }
    };

      // const convertDocxToPdf = async (docxBlob) => {
      //   return new Promise((resolve, reject) => {
      //     const file = new File([docxBlob], "temp.docx");
    
      //     convert(file, "temp.pdf", (err, result) => {
      //       if (err) {
      //         console.error("Erro ao converter para PDF:", err);
      //         reject(err);
      //       } else {
      //         resolve(new Blob([result], { type: "application/pdf" }));
      //       }
      //     });
      //   });
      // };

    const handleWordUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
        setLoadingUploadWord(true);
        const file = event.target.files?.[0];
    
        if (file) {
          const fileName = file.name;
          setTextArquive(fileName);
    
          //const placeholderData = formik ? formik.values : {}

          let placeholderData = {
            corporateName: formik && formik.values && formik.values.unitBranch && formik.values.unitBranch.company && formik.values.unitBranch.company.corporateName ? formik.values.unitBranch.company.corporateName : "",
            cnpj: formik && formik.values && formik.values.unitBranch && formik.values.unitBranch.company && formik.values.unitBranch.company.cnpj ? formik.values.unitBranch.company.cnpj : "",
            street: formik && formik.values && formik.values.unitBranch && formik.values.unitBranch.street ? formik.values.unitBranch.street : "",
            district: formik && formik.values && formik.values.unitBranch && formik.values.unitBranch.district ? formik.values.unitBranch.district : "",
            number: formik && formik.values && formik.values.unitBranch && formik.values.unitBranch.number ? formik.values.unitBranch.number : "",
            telephone: formik && formik.values && formik.values.unitBranch && formik.values.unitBranch.telephone ? formik.values.unitBranch.telephone : "",
            emailCompany: formik && formik.values && formik.values.unitBranch && formik.values.unitBranch.emailCompany ? formik.values.unitBranch.emailCompany : "",
            number_order_service: formik && formik.values && formik.values.number_order_service ? formik.values.number_order_service : "",
            dateCreate: formik && formik.values && formik.values.created_at ? formatarData(formik.values.created_at) : "",
            nameResponsible: formik && formik.values && formik.values.client && formik.values.client.nameResponsible ? formik.values.client.nameResponsible : "",
            cpfResponsible: formik && formik.values && formik.values.client && formik.values.client.cpfResponsible ? formik.values.client.cpfResponsible : "",
            telephoneClient: formik && formik.values && formik.values.client && formik.values.client.telephone ? formik.values.client.telephone : "0000-000",
            cellphoneClient: formik && formik.values && formik.values.client && formik.values.client.cellphone ? formik.values.client.cellphone : "(00) 0 0000-0000",
            emailClient: formik && formik.values && formik.values.client && formik.values.client.email ? formik.values.client.email : "",
            namePlant: formik && formik.values && formik.values.plant && formik.values.plant.name ? formik.values.plant.name : "",
            addressPlant: formik && formik.values && formik.values.plant && formik.values.plant.address ? formik.values.plant.address : "",
            titulo: formik && formik.values && formik.values.title ? formik.values.title : "",
            status: formik && formik.values && formik.values.status ? formik.values.status : "",
            description: formik && formik.values && formik.values.description ? formik.values.description : "",
            companyName: formik && formik.values && formik.values.unitBranch && formik.values.unitBranch.companyName ? formik.values.unitBranch.companyName : "",
            value_sub_final: formik && formik.values && formik.values.value_sub_final ? formik.values.value_sub_final : "0",
            value_discont: formik && formik.values && formik.values.value_discont ? formik.values.value_discont : "0",
            value_final: formik && formik.values && formik.values.value_final ? formik.values.value_final : "0",
            itensProposal: formik && formik.values && formik.values.itensProposal ? 
            formik.values.itensProposal.map((item: any) => {
                const my_item = {
                    service: item ? 
                            item.tipo === "SERVICO" ? 
                                item.typeService && item.typeService.description ? item.typeService.description 
                            : "" 
                        : item.tipo === "PRODUTO" ? 
                            item.product && item.product.description ? item.product.description 
                            : "" 
                        : ""  
                    : "",
                    description: item && item.description ? item.description : "",
                    value: item && item.value ? item.value : "",
                    amount: item && item.amount ? item.amount : "",
                    sub_value: item && item.sub_value ? item.sub_value : "",
                }
                return my_item
            }) : []
    
            }

            console.log(placeholderData)
          try {
            //await processWordDocument(file, placeholderData);
            await processDocx(file, placeholderData);
          } catch (error) {
            console.error("Erro ao processar o documento:", error);
          }
          // try {
          //   const processedDocx: any = await processDocx(file, placeholderData);
      
          //   saveAs(processedDocx, "processed-document.docx");
      
          //   const pdfBlob: any = await convertDocxToPdf(processedDocx);
      
          //   saveAs(pdfBlob, "output.pdf");
          //   console.log("PDF gerado com sucesso!");
          // } catch (error) {
          //   console.error("Erro ao processar ou converter o arquivo:", error);
          // }
          
        }
        setLoadingUploadWord(false);
    };

    const postTemplate = async () => {
      try {
        setLoading(true)
        const myData = {
          textHTML: formikTemplate.values.textHTML,
          description: formikTemplate.values.description,
          type: user.currentUser?.role.slug !== "ADMIN" ? "private" : "public",
          typeDoc: "docx",
          titleDocx: formikTemplate.values.titleDocx ? formikTemplate.values.titleDocx : null,
          linkDocx: formikTemplate.values.linkDocx ? formikTemplate.values.linkDocx : null,
          //type_template: formik.values.type_template,
          idUnitBranch : formik.values.idUnitBranch
        }
   
        const myTemplate = await postTemplateProposalOne(myData)
        //setInitialHtml(myTemplate.textHTML)
  
        if(myTemplate){
          let formik_templates = formik.values.idsTemplates;
          const result = formik_templates.map(id => myTemplates.find(template => template.id === id) || null);
  
          const index = result.findIndex(template => template.type_template === myTemplate.type_template);
          if(index === -1){
            formik_templates.push(myTemplate.id)
          }else{
            formik_templates[index] = myTemplate.id
          }
          formik.setValues({...formik.values, idsTemplates: formik_templates})
  
          //formikReport.setValues({...formik.values, idTemplate: template.id})
  
          setMyTemplate(myTemplate)
        }
  
        toggleToast("Sucesso ao cadastrar o template. ", "success")
  
      } catch (error) {
        console.log(error)
        toggleToast("Erro ao cadastrar template. ", "error")
      }finally{
        setLoading(false)
        refresh()
        setGenerateTemplate(false)
      }
    }

    const getAllUnitBranchs = async () => {
      try {
        setLoadingUnitBranchs(true)
        const unitBranchs = await getUnitBranchAll()
        setUnitBranchs(unitBranchs)
  
      } catch (error) {
        console.log(error)
        toggleToast("Erro ao buscar a lista de unidades. ", "error")
  
      }finally{
        setLoadingUnitBranchs(false)
      }
    }

    const submit = (type: number) => {
      if(type === 1){
        if(mySelectTemplate && mySelectTemplate.typeDoc === 'docx'){
          //putTemplate(mySelectTemplate.id)
        }
      }else if (type === 0){
        postTemplate()
      }
    }

      useEffect(() => {
        if(mySelectTemplate && mySelectTemplate.typeDoc === 'docx'){
          const data = {
            textHTML: "<h1>Não encontrado.. </h1>",
            description: mySelectTemplate.description ? mySelectTemplate.description : null,
            type: mySelectTemplate.type ? mySelectTemplate.type : null,
            type_template: mySelectTemplate.type_template ? mySelectTemplate.type_template : null,
            idUnitBranch: mySelectTemplate.unitBranch ? mySelectTemplate.unitBranch.id : null,
            typeDoc: "docx",
            titleDocx: mySelectTemplate.titleDocx ? mySelectTemplate.titleDocx.id : null,
            linkDocx: mySelectTemplate.linkDocx ? mySelectTemplate.linkDocx.id : null,
          }
          formikTemplate.setValues(data)
        }
      // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [mySelectTemplate]);

    useEffect(() => {
      console.log(formikTemplate.values)
    }, [formikTemplate.values])

  return (
    <div className='bg-light' style={{ display: 'flex', gap: '1rem', padding: '1rem', width: '100%', flexDirection: 'column' , height: '50rem', overflow: 'auto'}}>
        <div className='bg-body' style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '1rem'}}>
            <h3 className='align-items-start flex-column' style={{display: "flex"}}>
                <span className='card-label fw-bold fs-3 mb-1'>Template em documento</span>
                <span className='text-muted fw-semibold fs-7'>Suba um documento .docx como template</span>
            </h3>
        </div>

        <div className='border border-gray-300 border-dashed rounded'>
          <div className="d-flex align-items-center justify-content-center" style={{width: '100%', height: '41rem', flexDirection: 'row'}}>
            <input
              type="file"
              accept=".docx"
              ref={fileInputRef}
              disabled={loadingUploadWord}
              onChange={handleWordUpload}
              style={{ display: 'none' }}
            />
            {/* <Button 
              onClick={handleButtonClick}
            >
              Escolher Arquivo
            </Button> */}

            <button
              onClick={() =>{handleButtonClick()}}
              disabled={loadingUploadWord}
              style={{width: '100%', height: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', gap: '0.5rem'}}
              className='btn btn-sm btn-light-primary text-hover-white'
            >
              <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'start'}}>
                <KTSVG path='/media/icons/duotune/files/fil009.svg' className='svg-icon-2x' />
                <h3 className='card-title align-items-start flex-column'>
                  {/* <span className='card-label fw-bold fs-3 mb-1'>Adicionar Cliente (Planilha)</span> */}
                  <span className='mt-1 fw-semibold fs-7'>{textArquive}</span>
                </h3>
              </div>

              {loadingUploadWord && (
                <div style={{marginRight: '1rem'}}>
                  <LoadingSpan style={{marginLeft: '0.5rem'}}/>
                </div>
            )}
            </button>

          </div>
        </div>

        <div style={{display: 'flex', gap: '1rem'}}>
          <button
            type='button'
            className='btn btn-outline-success'
            onClick={() => setGenerateTemplate(true)}
          >
            Gerar Template
            {
              loading ? 
              <LoadingSpan style={{marginLeft: '0.5rem'}}/>
              :
              <KTSVG
                  path='/media/icons/duotune/arrows/arr064.svg'
                  className='svg-icon-3 ms-2 me-0'
              />
            }
          </button>

          <button
              type='button'
              className='btn btn-outline-primary'
              //disabled={!mySelectTemplate.id || mySelectTemplate.type === "public" ? true : false}
              //data-kt-stepper-action='submit'
              onClick={() => {
                  setGenerateTemplate(false)
                  submit(1)
              }}
              //onClick={putTemplate}
              >
              Atualizar Template
              {
                  loading ? 
                  <LoadingSpan style={{marginLeft: '0.5rem'}}/>
                  :
                  <KTSVG
                      path='/media/icons/duotune/arrows/arr064.svg'
                      className='svg-icon-3 ms-2 me-0'
                  />
              }
          </button>
      </div>

      {generateTemplate ? 
      loadingUnitBranchs ?
        <><h1>Carregando..</h1></>
      :
        <div>
          <div className='mb-10' style={{display: 'flex', gap: '1rem', alignItems: 'center'}}>
            <div style={{flex: '1'}}>
              <label className='d-flex align-items-center fs-6 fw-semibold mb-2'>
                  <span className='required'>Descrição do template</span>
                  <i
                  className='fas fa-exclamation-circle ms-2 fs-7'
                  data-bs-toggle='tooltip'
                  title='Fim do período de análise do relatório'
                  ></i>
              </label>
            </div>
            <div style={{flex: '3'}}>
              <input
                  type='text'
                  className='form-control form-control-lg'
                  name='endPeriod'
                  disabled={loading}
                  placeholder=''
                  value={formikTemplate.values.description ? formikTemplate.values.description : ""}
                  onChange={formikTemplate.handleChange("description")}
              />
            </div>
          </div>

          <div className='mb-10' style={{display: 'flex', gap: '1rem', alignItems: 'center'}}>
            <div style={{flex: '1'}}>
              <label className='d-flex align-items-center fs-6 fw-semibold mb-2'>
                  <span className='required'>Titulo do documento</span>
                  <i
                  className='fas fa-exclamation-circle ms-2 fs-7'
                  data-bs-toggle='tooltip'
                  title='Fim do período de análise do relatório'
                  ></i>
              </label>
            </div>
            <div style={{flex: '3'}}>
              <input
                  type='text'
                  className='form-control form-control-lg'
                  name='endPeriod'
                  disabled={loading}
                  placeholder=''
                  value={formikTemplate.values.titleDocx ? formikTemplate.values.titleDocx : ""}
                  onChange={formikTemplate.handleChange("titleDocx")}
              />
            </div>
          </div>

          <div style={{}}>
            <div style={{}}>
              <div className='mb-10' style={{display: 'flex', gap: '1rem'}}>
                <label className='d-flex align-items-center fs-5 fw-semibold mb-2'>
                  <span className='required'>Unidade (Filial)</span>
                  <i
                    className='fas fa-exclamation-circle ms-2 fs-7'
                    data-bs-toggle='tooltip'
                    title='Selecione para qual unidade filial essa credencial será vinculada. '
                  ></i>
                </label>
                <div style={{flexGrow: '1', display: 'flex', gap: '1rem'}}>
                  <select
                    className='form-select form-select-solid bg-white'
                    data-kt-select2='true'
                    disabled={loading}
                    data-placeholder='Select option'
                    onClick={() => {getAllUnitBranchs()}}
                    data-allow-clear='true'
                    defaultValue={formikTemplate.values.idUnitBranch ? formikTemplate.values.idUnitBranch : '1'}
                    onChange={formikTemplate.handleChange("idUnitBranch")}
                  >
                    <option value={'1'}>Selecione uma Unidade (Filial)</option>
                    {unitBranchs && unitBranchs.map((myUnitBranch: any) => {
                      return (
                        <option key={myUnitBranch.id} value={myUnitBranch.id}>{myUnitBranch.companyName}</option>
                      )
                    })}
                    
                  </select>

                    {/* <select
                    className='form-select form-select-solid bg-white'
                    data-kt-select2='true'
                    disabled={loading}
                    data-placeholder='Select option'
                    data-allow-clear='true'
                    defaultValue={formik.values.type_template ? formik.values.type_template : 'DETALHADO'}
                    onChange={formik.handleChange("type_template")}
                  >
                    <option value={'GERACAO'}>Geração</option>
                    <option value={'DETALHADO'}>Detalhado</option>
                    
                  </select> */}

                </div>
              </div>
            </div>
          </div>

          <div style={{display: 'flex', justifyContent: 'space-between'}}>
            <button
              type='button'
              className='btn btn-lg btn-light'
              disabled={loading}
              onClick={() => {
                setGenerateTemplate(false)
              }}
              >
                Cancelar
                {
                  loading ? 
                  <LoadingSpan style={{marginLeft: '0.5rem'}}/>
                  :
                  <KTSVG
                      path='/media/icons/duotune/arrows/arr063.svg'
                      className='svg-icon-3 ms-2 me-0'
                  />
                }
            </button>

            <button
                type='button'
                className='btn btn-lg btn-primary'
                onClick={() => submit(0)}
              >
                Gerar
                {
                  loading ? 
                  <LoadingSpan style={{marginLeft: '0.5rem'}}/>
                  :
                  <KTSVG
                      path='/media/icons/duotune/arrows/arr064.svg'
                      className='svg-icon-3 ms-2 me-0'
                  />
                }
            </button>
          </div>

        </div> 

      : <></>
    }
    </div>
  );
}