export function addLoadingCredential(credential: any){
    var list_imports = sessionStorage.getItem('list_imports');
        
    if(list_imports){
        var my_list_imports = JSON.parse(list_imports);
        my_list_imports.push({credential: credential.username, status: true, id: credential.id})
        var arrayString = JSON.stringify(my_list_imports);
        sessionStorage.setItem('list_imports', arrayString);
    
    }else{
        let my_list_import: any[] = [];
        my_list_import.push({credential: credential.username, status: true, id: credential.id})
        var arrayString = JSON.stringify(my_list_import);
        sessionStorage.setItem('list_imports', arrayString);
    }
}

export function removeLoadingCredential(idCredential: string){
    var list_imports_finaly = sessionStorage.getItem('list_imports');

    if(list_imports_finaly){
        var my_list_imports_finaly = JSON.parse(list_imports_finaly);
        const list_filter = my_list_imports_finaly.filter((item: any) => item.id != idCredential)
        var arrayString = JSON.stringify(list_filter);
        sessionStorage.setItem('list_imports', arrayString);
    }
}