/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react'
//import {Dropdown1} from '../../../../../_metronic/partials/content/dropdown/Dropdown1'
//import {KTSVG} from '../../../../../_metronic/helpers'
//import { getCredential } from '../../../../services/credentialService'
//import { CreateAppModal } from '../../../../../_metronic/partials'
//import { ModalCreateCredential } from '../modalCreateCredential'
import { Link } from 'react-router-dom'
import { KTSVG, toAbsoluteUrl } from '../../../../_metronic/helpers'
import { useAuth } from '../../../modules/auth'
import { getCredential } from '../../../services/credentialService'
import { getplantsAll } from '../../../services/plantsService'
import { ModalCreateCredential } from '../components/modalCreateCredential'
import { ModalDeleteCredential } from '../components/ModalDeleteCredential'
import { LoadingSpan } from '../../components/Loadings/loadingSpan'

type Props = {
  className: string
  color: string
  mark: any;
  credentials: any
  refreshCredentials: any
}

const MyPortal: React.FC<Props> = ({className, color, mark, credentials, refreshCredentials}) => {
    //const [credentials, setCredentials] = useState<any>([]);
    const [plants, setPlants] = useState<any>([]);
    const [credentialLoading, setCredentialLoading] = useState<any>(true);
    const [setPlantsLoading] = useState<any>(true);
    const [showCreateAppModal, setShowCreateAppModal] = useState<boolean>(false)
    const [showDeleteAppModal, setShowDeleteAppModal] = useState<boolean>(false)
    const [myCredential, setMyCredential] = useState<any>(null);
    const user = useAuth()
    
    const getCredentials = async () => {
      try {
        setCredentialLoading(true)
        // const myCredentials = await getCredential(mark.sub_name)
        // setCredentials(myCredentials)
        setCredentialLoading(false)

      } catch (error) {
        console.log(error)
      }
    }

    const getPlants = async () => {
      try {
        setPlantsLoading(true)
        const myPlants = await getplantsAll(`?mark=${mark.id}`)
        setPlants(myPlants)
        setPlantsLoading(false)

      } catch (error) {
        console.log(error)
      }
    }

    const refresh = async () => {
      refreshCredentials()
    }

    const eventEdit = (credential: any) => {
      setMyCredential(credential)
      setShowCreateAppModal(true)
    }

    const eventDelete = (credential: any) => {
      setMyCredential(credential)
      setShowDeleteAppModal(true)
    }


    useEffect(() => {
      getCredentials()
      if(user.currentUser?.role.slug === "CLIENTE"){
        getPlants()
      }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    
  return (
    <div className={`card ${className}`} style={{height: '100%'}}>
      {/* begin::Body */}
      <div className='card-body p-0'>
        {/* begin::Header */}
        <div className={`px-9 pt-6 card-rounded h-100px w-100 bg-${color}`}>
          {/* begin::Heading */}
          <div className='d-flex flex-stack'>
            <div style={{display: 'flex', alignItems: 'center'}}>
              <div className='symbol symbol-30px me-4 p-1 bg-light' >
                {mark && mark.link_imagem ? 
                  (<img
                      src={toAbsoluteUrl(`${mark.link_imagem}`)}
                      className=''
                      alt=''
                  />) 
                  :
                  (<KTSVG path='/media/icons/duotune/coding/cod009.svg' className='svg-icon-2x svg-icon-dark'/>)}
              </div>
              <h3 className='m-0 text-light fw-bold fs-4'>{mark.name}</h3>
            </div>
            
            <div className='ms-1' style={{height: '2rem', width: '2rem', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                
                <div className={`bg-${
                  mark.active ? "success" : "danger"
                } rounded-circle h-15px w-15px`}></div>
            
                {/*<Dropdown1 />*/}
              {/* end::Menu */}
            </div>
          </div>
          {/* end::Heading */}
          {/* begin::Balance */}
          {/*<div className='d-flex text-center flex-column text-white pt-8'>
            <span className='fw-semibold fs-7'>You Balance</span>
            <span className='fw-bold fs-2x pt-1'>$37,562.00</span>
            </div>*/}
          {/* end::Balance */}
        </div>
        {/* end::Header */}
        {/* begin::Items */}
        <div
          className='shadow-xs card-rounded mx-9 mb-9 px-6 py-9 position-relative z-index-1 bg-body'
          style={{marginTop: '-30px'}}
        >
          {/* begin::Item */}
            <div style={{maxHeight: '12rem', marginBottom: '0.7rem', overflow: 'auto'}}>
              {
                user.currentUser?.role.slug === "CLIENTE" ?
                  <div style={{marginRight: '1rem', display: 'flex', flexDirection: 'column', gap: '1rem'}}>
                    {
                      plants.length > 0 ?
                        plants.map((plant: any) => {
                          return (
                            <div className='d-flex align-items-center' style={{flex: '1', overflow: 'auto'}}>
                              <div className='d-flex align-items-center'>
                                <div className='symbol symbol-50px me-5'>
                                  <img
                                      src={toAbsoluteUrl('/media/imgs/plant2.png')}
                                      className=''
                                      alt=''
                                  />
                                </div>
                              </div>
                              <div className='mb-1 pe-3 flex-grow-1'>
                                <Link to={`#`} className='fs-7 text-gray-800 text-hover-primary fw-bold'>
                                    {plant.name}
                                </Link> 
                                {/* <div className='text-gray-400 fw-semibold fs-7'>***************</div> */}
                              </div>
                        
                            </div>
                          );
                        })
                      :
                        <div className='d-flex align-items-center' style={{flex: '1', overflow: 'auto'}}>
                          <span>Não há usinas nesse portal!</span>
                        </div>
                    }
                  </div>
                :
              !credentialLoading ? 
                      //mark && mark.credentials && mark.credentials.length > 0 ?
                       //mark.credentials.map((credential: any) => {
                       credentials && credentials.length > 0 ?
                       credentials.map((credential: any) => {
                          return (
                              <div key={credential.id} className='d-flex align-items-center mb-4' style={{marginRight: '1rem'}}>
                                  <div className='symbol symbol-20px w-20px me-5'>
                                    <div
                                      //className={`btn btn-sm btn-icon btn-color-black btn-active-white btn-active-color-${color} border-0 me-n3`}
                                      className={`menu-item px-5 btn btn-sm btn-icon btn-color-black btn-active-white btn-active-color-${color} border-0 me-n3`}
                                      data-kt-menu-trigger='hover'
                                      data-kt-menu-placement='bottom-start'
                                      data-kt-menu-flip='top-start'
                                    >
                                      <a href='#' className='menu-link px-5'>
                                        <span className='menu-title'>
                                          <KTSVG path='/media/icons/duotune/general/gen024.svg' className='svg-icon-2 svg-icon-3x'  />
                                        </span>
                                      </a>

                                      <div className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg menu-state-primary fw-bold py-4 fs-6 w-175px py-4' data-kt-menu='true'>
                                          <div 
                                              className='menu-item px-3' 
                                              onClick={() => {
                                                eventEdit(credential)
                                              }}
                                          >
                                            <a href='#' className='menu-link px-5'>
                                              Editar
                                            </a>
                                          </div>

                                          <div 
                                              className='menu-item px-3' 
                                              onClick={() => {
                                                eventDelete(credential)
                                              }}
                                          >
                                            <a href='#' className='menu-link px-5'>
                                              Excluir
                                            </a>
                                          </div>

                                        {/* <div className='menu-item px-3'>
                                          <a href='#' className='menu-link d-flex flex-stack px-5'>
                                            Excluir
                                            <i
                                              className='fas fa-exclamation-circle ms-2 fs-7'
                                              data-bs-toggle='tooltip'
                                              title='Excluir credenciais desinportará todas as uninas relacionadas a mesma.'
                                            ></i>
                                          </a>
                                        </div>

                                        <div className='separator my-2'></div> */}

                                        {/* <div className='menu-item px-3'>
                                          <div className='menu-content px-3'>
                                            <label className='form-check form-switch form-check-custom form-check-solid'>
                                              <input
                                                className='form-check-input w-30px h-20px bg-primary'
                                                type='checkbox'
                                                value='1'
                                                defaultChecked={true}
                                                name='notifications'
                                              />
                                              <span className='form-check-label text-muted fs-7'>Desativar</span>
                                            </label>
                                          </div>
                                        </div> */}
                                      </div>
                                    </div>
                                    
                                  </div>
                                  <div className='d-flex align-items-center flex-wrap' style={{flex: '1', overflow: 'auto'}}>
                                      <div className='mb-1 pe-3 flex-grow-1'>
                                        <Link to={`/portals/plantImported/${credential.id}`} className='fs-7 text-gray-800 text-hover-primary fw-bold'>
                                            {credential.username}
                                        </Link> 
                                        <div className='text-gray-400 fw-semibold fs-7'>***************</div>
                                      </div>
                                      <div className='d-flex align-items-center'>
                                      {/* <div className='fw-bold fs-7 text-gray-800 pe-1'>( 1 )</div> */}

                                      </div>
                                  </div>
                              </div>
                              
                      )})
                      : 
                      <div className='d-flex align-items-center mb-6'>
                          <span>Não há credenciais para esse portal.</span>
                      </div>
              : 
                <div className='d-flex align-items-center mb-6'>
                  <LoadingSpan style={{marginLeft: '0.5rem'}}/>
                </div>
              }
            </div>

            {user.currentUser?.role.slug !== "CLIENTE" ?
              <button 
                className={`btn btn-primary w-100 py-3 fs-7`} 
                //disabled={mark.active && credentials.length === 0 ? false : true}
                disabled={mark.active ? false : true}
                onClick={() => setShowCreateAppModal(true)}
              >
                Adicionar credencial
              </button>
              : <></>
            }
            
            

        </div>
        {/* end::Items */}
      </div>
      {/* end::Body */}
      <ModalCreateCredential 
        show={showCreateAppModal}
        id={myCredential && myCredential.id ? myCredential.id : undefined} 
        handleClose={() => {
          setMyCredential(null)
          setShowCreateAppModal(false)
          refresh()
        }} 
      mark={mark}/>

      <ModalDeleteCredential 
        show={showDeleteAppModal}
        data={myCredential}
        id={myCredential && myCredential.id ? myCredential.id : undefined} 
        handleClose={() => {
          setMyCredential(null)
          setShowDeleteAppModal(false)
          refresh()
        }} 
      />
    </div>
  )
}

export {MyPortal}

// function toggleToast(arg0: string, arg1: string) {
//   throw new Error('Function not implemented.')
// }

