/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import {useState, useRef, useEffect} from 'react'
import {createPortal} from 'react-dom'
import {Modal} from 'react-bootstrap'
import * as Yup from "yup";

import { StepperComponent } from '../../../../../../_metronic/assets/ts/components'
import { useToast } from '../../../Toats'
import { KTSVG, toAbsoluteUrl } from '../../../../../../_metronic/helpers'
import { LoadingSpan } from '../../../Loadings/loadingSpan'
import { useFormik } from 'formik';
import { postPrognosisOne, putPlantOne, putPrognosisOne } from '../../../../../services/plantsService';
import useSize from '../../../../../hooks/useSize';
import { useAuth } from '../../../../../modules/auth';
import { MapsV2Plant } from '../../../../dashboard/MapsV2Plant';
import Autocomplete from "react-google-autocomplete";
import axios from 'axios';

type Props = {
    id: string;
    data: any;
    show: boolean;
    handleClose: () => void
}

const validationSchema = Yup.object().shape({
  value_implementation: Yup.number(),
  annual_generation_forecast: Yup.number(),

  prog_abr: Yup.number(),
  prog_ago: Yup.number(),
  prog_dez: Yup.number(),
  prog_fev: Yup.number(),
  prog_jan: Yup.number(),
  prog_jul: Yup.number(),
  prog_jun: Yup.number(),
  prog_mar: Yup.number(),
  prog_may: Yup.number(),
  prog_nov: Yup.number(),
  prog_oct: Yup.number(),
  prog_sep: Yup.number(),
});


const modalsRoot = document.getElementById('root-modals') || document.body

const ModalUpdatePlant = ({show, handleClose, id, data}: Props) => {

  const { toggleToast } = useToast();
  const stepperRef = useRef<HTMLDivElement | null>(null)
  const stepper = useRef<StepperComponent | null>(null)
  const [loading, setLoading] = useState(false)
  const {currentUser} = useAuth()

  const inputRef = useRef(null);
  const [address, setAddress] = useState("");


  const [initialValues] = useState<any>({
    value_implementation: data.value_implementation ? data.value_implementation : 0,
    annual_generation_forecast: data.annual_generation_forecast ? data.annual_generation_forecast : 0,
    
    plantName: data && data.plantName ? data.plantName : null,
    capacity: data && data.capacity ? data.capacity : null,
    plano: data && data.plano ? data.plano : null,
    
    address: data && data.address ? data.address : null,
    addressReduce: data && data.addressReduce ? data.addressReduce : null,

    plantLat: data && data.plantLat ? data.plantLat : null,
    plantLon: data && data.plantLon ? data.plantLon : null,

    

    number_panels : data.number_panels ? data.number_panels : 0,
    description_kit_instalation : data.description_kit_instalation ? data.description_kit_instalation : null,
    tensao : data.tensao ? data.tensao : 0,
    type_conection : data.type_conection ? data.type_conection : null,
    economy_total_year : data.economy_total_year ? data.economy_total_year : 0,

    prog_abr: data && data.prognosis && data.prognosis.prog_abr ? data.prognosis.prog_abr : 0,
    prog_ago: data && data.prognosis && data.prognosis.prog_ago ? data.prognosis.prog_ago : 0,
    prog_dez: data && data.prognosis && data.prognosis.prog_dez ? data.prognosis.prog_dez : 0,
    prog_fev: data && data.prognosis && data.prognosis.prog_fev ? data.prognosis.prog_fev : 0,
    prog_jan: data && data.prognosis && data.prognosis.prog_jan ? data.prognosis.prog_jan : 0,
    prog_jul: data && data.prognosis && data.prognosis.prog_jul ? data.prognosis.prog_jul : 0,
    prog_jun: data && data.prognosis && data.prognosis.prog_jun ? data.prognosis.prog_jun : 0,
    prog_mar: data && data.prognosis && data.prognosis.prog_mar ? data.prognosis.prog_mar : 0,
    prog_may: data && data.prognosis && data.prognosis.prog_may ? data.prognosis.prog_may : 0,
    prog_nov: data && data.prognosis && data.prognosis.prog_nov ? data.prognosis.prog_nov : 0,
    prog_oct: data && data.prognosis && data.prognosis.prog_oct ? data.prognosis.prog_oct : 0,
    prog_sep: data && data.prognosis && data.prognosis.prog_sep ? data.prognosis.prog_sep : 0,
  })
  const {width} = useSize()
  
  const loadStepper = () => {
    stepper.current = StepperComponent.createInsance(stepperRef.current as HTMLDivElement)
  }

  const closed = () => {
    handleClose()
  }

  const updatePlantSubmit = async () => {
    try {
      setLoading(true)
      const body = {
        value_implementation: formik.values.value_implementation,
        annual_generation_forecast: formik.values.annual_generation_forecast,

        plantName: formik.values.plantName ? formik.values.plantName : null,
        capacity: formik.values.capacity ? formik.values.capacity : null,
        plano: formik.values.plano ? formik.values.plano : null,
        
        address: formik.values.address ? formik.values.address : null,
        addressReduce: formik.values.addressReduce ? formik.values.addressReduce : null,

        plantLat: formik.values.plantLat ? formik.values.plantLat : null,
        plantLon: formik.values.plantLon ? formik.values.plantLon : null,

        number_panels : formik.values.number_panels ? formik.values.number_panels : 0,
        description_kit_instalation : formik.values.description_kit_instalation ? formik.values.description_kit_instalation : null,
        tensao : formik.values.tensao ? formik.values.tensao : 0,
        type_conection : formik.values.type_conection ? formik.values.type_conection : null,
        economy_total_year : formik.values.economy_total_year ? formik.values.economy_total_year : 0,
      };

      const bodyV2 = {
        prog_abr: formik.values.prog_abr ? formik.values.prog_abr : 0,
        prog_ago: formik.values.prog_ago ? formik.values.prog_ago : 0,
        prog_dez: formik.values.prog_dez ? formik.values.prog_dez : 0,
        prog_fev: formik.values.prog_fev ? formik.values.prog_fev : 0,
        prog_jan: formik.values.prog_jan ? formik.values.prog_jan : 0,
        prog_jul: formik.values.prog_jul ? formik.values.prog_jul : 0,
        prog_jun: formik.values.prog_jun ? formik.values.prog_jun : 0,
        prog_mar: formik.values.prog_mar ? formik.values.prog_mar : 0,
        prog_may: formik.values.prog_may ? formik.values.prog_may : 0,
        prog_nov: formik.values.prog_nov ? formik.values.prog_nov : 0,
        prog_oct: formik.values.prog_oct ? formik.values.prog_oct : 0,
        prog_sep: formik.values.prog_sep ? formik.values.prog_sep : 0,
        idPlant: id ? id : ""
      }
      await putPlantOne(id, body);
      
      if(data && data.prognosis){
        await putPrognosisOne(data.prognosis.id, bodyV2);
      }else{
        await postPrognosisOne(bodyV2)
      }
      

      toggleToast("Sucesso ao atualizar as informaçoes usina. ", "success")

    } catch (error) {
      console.log(error)
      toggleToast("Erro ao atualizar as informações da usina. ", "error")
    }finally{
      setLoading(false)
      closed()
      // handleClose()
    //   setTimeout(()=>{
    //     window.location.reload()
    //   }, 2000);
    }
  }

  const submit = () => {
    updatePlantSubmit()
  }

  const dividerValues = () => {
    const value = formik.values.annual_generation_forecast;
    if(value){
      const value_mes = Number((value/12).toFixed(2));

      formik.setValues({
        ...formik.values,
        prog_abr: value_mes,
        prog_ago: value_mes,
        prog_dez: value_mes,
        prog_fev: value_mes,
        prog_jan: value_mes,
        prog_jul: value_mes,
        prog_jun: value_mes,
        prog_mar: value_mes,
        prog_may: value_mes,
        prog_nov: value_mes,
        prog_oct: value_mes,
        prog_sep: value_mes
      })
    }
  }

  const formik = useFormik({
    initialValues,
    validationSchema,
    validateOnChange: false,
    onSubmit: submit,
  });


  const [query, setQuery] = useState("");
  const [suggestions, setSuggestions] = useState([]);

  const fetchSuggestions = async (input) => {
    if (input.length < 3) return;

    try {
      const response = await axios.get(
        `https://api.mapbox.com/geocoding/v5/mapbox.places/${encodeURIComponent(
          input
        )}.json`,
        {
          params: {
            access_token: "pk.eyJ1IjoibGVra293aWxsaWFtdG9ycmVzIiwiYSI6ImNsdThnazlyZDBmcXEya29qMmZndGtmMmkifQ.kwHp_K24hVrkVHWqTE9yEQ",
            autocomplete: true,
            types: "place,address",
            language: "pt-BR",
          },
        }
      );

      setSuggestions(response.data.features || []);
    } catch (error: any) {
      console.error("Erro ao buscar sugestões:", error.response?.data || error.message);
    }
  };

  function isValidCoordinate(value, min, max) {
    const num = parseFloat(value);
    return !isNaN(num) && num >= min && num <= max;
}

const getStateAndCountry = async (latitude, longitude) => {
  if (
    !isValidCoordinate(latitude, -90, 90) ||
    !isValidCoordinate(longitude, -180, 180)
  ) {
    console.error("Coordenadas inválidas:", { latitude, longitude });
    return null;
  }

  try {
    const response = await axios.get(
      `https://api.mapbox.com/geocoding/v5/mapbox.places/${longitude},${latitude}.json`,
      {
        params: {
          access_token:
            "pk.eyJ1IjoibGVra293aWxsaWFtdG9ycmVzIiwiYSI6ImNsdThnazlyZDBmcXEya29qMmZndGtmMmkifQ.kwHp_K24hVrkVHWqTE9yEQ", // Insira seu token aqui
          language: "pt-BR",
          types: "place,region,country",
        },
      }
    );

    if (response.data && response.data.features) {
      const features = response.data.features;

      const cityFeature = features.find((feature) =>
        feature.place_type.includes("place")
      );
      const stateFeature = features.find((feature) =>
        feature.place_type.includes("region")
      );
      const countryFeature = features.find((feature) =>
        feature.place_type.includes("country")
      );

      const city = cityFeature ? cityFeature.text : "Cidade não encontrada";
      const state = stateFeature ? stateFeature.text : "Estado não encontrado";
      const country = countryFeature
        ? countryFeature.text
        : "País não encontrado";

      console.log(`${city}, ${state}, ${country}`);

      formik.setValues({
        ...formik.values, addressReduce: `${city}, ${state}, ${country}`});

      return { city, state, country };
    }

    console.warn("Nenhum dado encontrado para as coordenadas:", {
      latitude,
      longitude,
    });
    return null;
  } catch (error: any) {
    console.error(
      "Erro na chamada da API:",
      error.response?.data || error.message
    );
    return null;
  }
};



  useEffect(() => {
    formik.setValues({
      value_implementation: data && data.value_implementation ? data.value_implementation : 0,
      annual_generation_forecast: data && data.annual_generation_forecast ? data.annual_generation_forecast : 0,
      
      plantId: data && data.plantId ? data.plantId : "",
      plantName: data && data.plantName ? data.plantName : "",
      capacity: data && data.capacity ? data.capacity : "",
      plano: data && data.plano ? data.plano : "",
      
      address: data && data.address ? data.address : "",
      addressReduce: data && data.addressReduce ? data.addressReduce : null,

      plantLat: data && data.plantLat ? data.plantLat : "",
      plantLon: data && data.plantLon ? data.plantLon : "",


      number_panels : data.number_panels ? data.number_panels : 0,
      description_kit_instalation : data.description_kit_instalation ? data.description_kit_instalation : null,
      tensao : data.tensao ? data.tensao : 0,
      type_conection : data.type_conection ? data.type_conection : null,
      economy_total_year : data.economy_total_year ? data.economy_total_year : 0,


      prog_abr: data && data.prognosis && data.prognosis.prog_abr ? data.prognosis.prog_abr : 0,
      prog_ago: data && data.prognosis && data.prognosis.prog_ago ? data.prognosis.prog_ago : 0,
      prog_dez: data && data.prognosis && data.prognosis.prog_dez ? data.prognosis.prog_dez : 0,
      prog_fev: data && data.prognosis && data.prognosis.prog_fev ? data.prognosis.prog_fev : 0,
      prog_jan: data && data.prognosis && data.prognosis.prog_jan ? data.prognosis.prog_jan : 0,
      prog_jul: data && data.prognosis && data.prognosis.prog_jul ? data.prognosis.prog_jul : 0,
      prog_jun: data && data.prognosis && data.prognosis.prog_jun ? data.prognosis.prog_jun : 0,
      prog_mar: data && data.prognosis && data.prognosis.prog_mar ? data.prognosis.prog_mar : 0,
      prog_may: data && data.prognosis && data.prognosis.prog_may ? data.prognosis.prog_may : 0,
      prog_nov: data && data.prognosis && data.prognosis.prog_nov ? data.prognosis.prog_nov : 0,
      prog_oct: data && data.prognosis && data.prognosis.prog_oct ? data.prognosis.prog_oct : 0,
      prog_sep: data && data.prognosis && data.prognosis.prog_sep ? data.prognosis.prog_sep : 0,
    })
   }, [show]);

   useEffect(() => {
    if(
      formik.values.prog_abr ||
      formik.values.prog_ago ||
      formik.values.prog_dez ||
      formik.values.prog_fev ||
      formik.values.prog_jan ||
      formik.values.prog_jul ||
      formik.values.prog_jun ||
      formik.values.prog_mar ||
      formik.values.prog_may ||
      formik.values.prog_nov ||
      formik.values.prog_oct ||
      formik.values.prog_sep
    ){
      formik.setValues({...formik.values, annual_generation_forecast : 
        Number((formik.values.prog_abr
        + formik.values.prog_ago
        + formik.values.prog_dez
        + formik.values.prog_fev
        + formik.values.prog_jan
        + formik.values.prog_jul
        + formik.values.prog_jun
        + formik.values.prog_mar
        + formik.values.prog_may
        + formik.values.prog_nov
        + formik.values.prog_oct
        + formik.values.prog_sep).toFixed(2))
      })
    }
    
   }, [
    formik.values.prog_abr,
    formik.values.prog_ago,
    formik.values.prog_dez,
    formik.values.prog_fev,
    formik.values.prog_jan,
    formik.values.prog_jul,
    formik.values.prog_jun,
    formik.values.prog_mar,
    formik.values.prog_may,
    formik.values.prog_nov,
    formik.values.prog_oct,
    formik.values.prog_sep,
   ]);


   useEffect(() => {
    console.log('chamou?')
    
    if(formik.values.plantLat
      && formik.values.plantLon
    ){

      getStateAndCountry(formik.values.plantLat, formik.values.plantLon)
    }

   }, [
    formik.values.plantLat, 
    formik.values.plantLon
  ])


  // useEffect(() => {
  //   formik.setValues({...formik.values, prog_jan: formik.values.annual_generation_forecast / 12})
  //   formik.setValues({...formik.values, prog_fev: formik.values.annual_generation_forecast / 12})
  //   formik.setValues({...formik.values, prog_mar: formik.values.annual_generation_forecast / 12})
  //   formik.setValues({...formik.values, prog_abr: formik.values.annual_generation_forecast / 12})
  //   formik.setValues({...formik.values, prog_may: formik.values.annual_generation_forecast / 12})
  //   formik.setValues({...formik.values, prog_jun: formik.values.annual_generation_forecast / 12})
  //   formik.setValues({...formik.values, prog_jul: formik.values.annual_generation_forecast / 12})
  //   formik.setValues({...formik.values, prog_ago: formik.values.annual_generation_forecast / 12})
  //   formik.setValues({...formik.values, prog_sep: formik.values.annual_generation_forecast / 12})
  //   formik.setValues({...formik.values, prog_oct: formik.values.annual_generation_forecast / 12})
  //   formik.setValues({...formik.values, prog_nov: formik.values.annual_generation_forecast / 12})
  //   formik.setValues({...formik.values, prog_dez: formik.values.annual_generation_forecast / 12})

  // }, [formik.values.annual_generation_forecast])


  return createPortal(
    <Modal
      id='kt_modal_create_app'
      tabIndex={-1}
      aria-hidden='true'
      dialogClassName='modal-dialog modal-dialog-centered modal-fullscreen'
      show={show}
      onHide={closed}
      onEntered={loadStepper}
      backdrop={true}
    >
      <div className='modal-header'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>Configurações da Usina {" - "}{data && data.name ? data.name : "Não identificado"}</span>
          {/* <span className='text-muted mt-1 fw-semibold fs-5'>( {unitBranch.name} )</span> */}
        </h3>

        {/* begin::Close */}
        <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={handleClose}>
          <KTSVG className='svg-icon-1' path='/media/icons/duotune/arrows/arr061.svg' />
        </div>
        {/* end::Close */}
      </div>

      <div className='modal-body py-lg-10 px-lg-10'>
        {/*begin::Stepper */}
        <div
          ref={stepperRef}
          className='stepper stepper-pills stepper-column d-flex flex-column flex-xl-row flex-row-fluid'
          id='kt_modal_create_app_stepper'
        >
         
          {/*begin::Content */}
          <div className='flex-row-fluid py-lg-0'>
            <div style={{display: 'flex', gap: '4rem', alignItems: 'center', width: '100%', justifyContent: 'space-between'}}>
             
              <div style={{flex: '1'}}>
                <div className='fv-row' style={{marginBottom: '1.5rem'}}>
                    <label className='d-flex align-items-center fs-6 fw-semibold mb-2'>
                        <span>ID da usina no Solar Vision</span>
                        <i
                        className='fas fa-exclamation-circle ms-2 fs-7'
                        data-bs-toggle='tooltip'
                        title='Id da usina'
                        ></i>
                    </label>
                    <input
                        type='text'
                        disabled={true}
                        className='form-control form-control-lg'
                        name='id_usina'
                        placeholder=''
                        value={formik.values.plantId}
                        onChange={formik.handleChange("plantId")}
                    />
                  </div>

                  <div style={{display: 'flex', gap: '0.5rem', flexWrap: 'wrap'}}>
                    <div className='fv-row' style={{marginBottom: '1.5rem', flex: '1', minWidth: '15rem'}}>
                      <label className='d-flex align-items-center fs-6 fw-semibold mb-2'>
                          <span>Nome da usina</span>
                          <i
                          className='fas fa-exclamation-circle ms-2 fs-7'
                          data-bs-toggle='tooltip'
                          title='Nome da usina'
                          ></i>
                      </label>
                      <input
                          type='text'
                          //disabled={data && data.plano && data.plano !== "PREMIUM" ? true : false}
                          className='form-control form-control-lg'
                          name='plantName'
                          placeholder=''
                          value={formik.values.plantName}
                          onChange={formik.handleChange("plantName")}
                      />
                    </div>
                    <div className='fv-row' style={{marginBottom: '1.5rem', flex: '1', minWidth: '15rem'}}>
                      <label className='d-flex align-items-center fs-6 fw-semibold mb-2'>
                          <span>Plano da usina</span>
                          <i
                          className='fas fa-exclamation-circle ms-2 fs-7'
                          data-bs-toggle='tooltip'
                          title='Valor da implantação da usina'
                          ></i>
                      </label>
                      <select
                          //style={{width: width < 1200 ? '100%' : '20rem'}}
                          className='form-select form-select-solid'
                          data-kt-select2='true'
                          data-placeholder='Select option'
                          data-allow-clear='true'
                          defaultValue={''}
                          value={formik.values.plano}
                          onChange={(e) => {
                            formik.setValues({...formik.values, plano: e.target.value})
                          }}
                        >
                          <option value={''} disabled>Selecione um plano</option>
                          {
                            currentUser 
                            && currentUser.companies 
                            && currentUser.companies.length > 0 
                            && currentUser.companies[0].planosCompany
                            && currentUser.companies[0].planosCompany.qtd_plants_plano > 0
                            && (
                              <option value={'PREMIUM'}>PREMIUM</option>
                            )
                          }
                          {
                            currentUser 
                            && currentUser.companies 
                            && currentUser.companies.length > 0 
                            && currentUser.companies[0].planosCompany
                            && currentUser.companies[0].planosCompany.qtd_plants_plano_starter > 0
                            && (
                              <option value={'STARTER'}>STARTER</option>
                            )
                          }

                          {
                            currentUser 
                            && currentUser.companies 
                            && currentUser.companies.length > 0 
                            && currentUser.companies[0].planosCompany
                            && currentUser.companies[0].planosCompany.qtd_plants_gratuita > 0
                            && (
                              <option value={'GRATUITA'}>GRATUITA</option>
                            )
                          }

                          {/* <option value={'PREMIUM'}>PREMIUM</option>
                          <option value={'STARTER'}>STARTER</option>
                          <option value={'GRATUITA'}>GRATUITA</option> */}

                      </select>
                    </div>
                  </div>
                  

                  <div style={{display: 'flex', gap: '0.5rem', flexWrap: 'wrap'}}>
                    <div className='fv-row' style={{marginBottom: '1.5rem', flex: '1'}}>
                      <label className='d-flex align-items-center fs-6 fw-semibold mb-2'>
                          <span>Potência</span>
                          <i
                          className='fas fa-exclamation-circle ms-2 fs-7'
                          data-bs-toggle='tooltip'
                          title='Potencia da usina'
                          ></i>
                      </label>
                      <input
                          type='number'
                          //disabled={data && data.plano && data.plano !== "PREMIUM" ? true : false}
                          className='form-control form-control-lg'
                          name='name'
                          placeholder=''
                          value={formik.values.capacity}
                          onChange={formik.handleChange("capacity")}
                      />
                    </div>


                    <div className='fv-row' style={{marginBottom: '1.5rem', flex: '1'}}>
                      <label className='d-flex align-items-center fs-6 fw-semibold mb-2'>
                          <span>Tensão</span>
                          <i
                          className='fas fa-exclamation-circle ms-2 fs-7'
                          data-bs-toggle='tooltip'
                          title='Tensão da usina'
                          ></i>
                      </label>
                      <input
                          type='number'
                          //disabled={data && data.plano && data.plano !== "PREMIUM" ? true : false}
                          className='form-control form-control-lg'
                          name='name'
                          placeholder=''
                          value={formik.values.tensao}
                          onChange={formik.handleChange("tensao")}
                      />
                    </div>

                    <div className='fv-row' style={{marginBottom: '1.5rem', flex: '1'}}>
                      <label className='d-flex align-items-center fs-6 fw-semibold mb-2'>
                          <span>Quantidade de paineis</span>
                          <i
                          className='fas fa-exclamation-circle ms-2 fs-7'
                          data-bs-toggle='tooltip'
                          title='Potencia da usina'
                          ></i>
                      </label>
                      <input
                          type='number'
                          //disabled={data && data.plano && data.plano !== "PREMIUM" ? true : false}
                          className='form-control form-control-lg'
                          name='number_panels'
                          placeholder=''
                          value={formik.values.number_panels}
                          onChange={formik.handleChange("number_panels")}
                      />
                    </div>

                    <div className='fv-row' style={{marginBottom: '1.5rem', flex: '1'}}>
                      <label className='d-flex align-items-center fs-6 fw-semibold mb-2'>
                          <span>Tipo de conexão</span>
                          <i
                          className='fas fa-exclamation-circle ms-2 fs-7'
                          data-bs-toggle='tooltip'
                          title='Tipo de conexão da usina'
                          ></i>
                      </label>
                      <select
                        //style={{width: width < 1200 ? '100%' : '20rem'}}
                        className='form-select form-select-solid'
                        data-kt-select2='true'
                        data-placeholder='Select option'
                        data-allow-clear='true'
                        defaultValue={''}
                        value={formik.values.type_conection}
                        onChange={(e) => {
                          formik.setValues({...formik.values, type_conection: e.target.value})
                        }}
                      >
                        <option value={''} disabled>Selecione uma conexão</option>
                        <option value={'on-grid'}>On-grid</option>
                        <option value={'off-grid'}>Off-grid</option>
                        <option value={'hibrido'}>Híbrido</option>
                      </select>
                  </div>
                  </div>
              </div>

              <div>
                <div 
                  className={`symbol symbol-${width < 1200 ? '150px' : '150px'} symbol-fixed position-relative divSubImg`} 
                >
                  <img src={toAbsoluteUrl('/media/imgs/plant2.png')} alt='Metornic' />
                  <div 
                    className={
                      `position-absolute translate-middle bottom-0 start-100 mb-6 bg-${
                        data.status !== null
                          ? data.status === 0
                          ? "success"
                          : data.status === 1
                          ? "danger"
                          : data.status === 2
                          ? "warning"
                          : data.status === 7 || data.status === 3
                          ? "info"
                          : "secondary"
                        : "secondary"
                      
                      } rounded-circle border border-4 border-white h-20px w-20px`}
                  ></div>

                </div>
              </div>

            </div>
            <div style={{marginBottom: '3rem'}}>
                <div className='fv-row' style={{marginBottom: '1.5rem', flex: '1'}}>
                  <label className='d-flex align-items-center fs-6 fw-semibold mb-2'>
                      <span>Descrição do Kit instalado</span>
                      <i
                      className='fas fa-exclamation-circle ms-2 fs-7'
                      data-bs-toggle='tooltip'
                      title='Descrição do kit instalado na usina'
                      ></i>
                  </label>
                  <input
                      type='text'
                      style={{height: '15rem'}}
                      className='form-control form-control-lg'
                      name='description_kit_instalation'
                      placeholder=''
                      value={formik.values.description_kit_instalation}
                      onChange={formik.handleChange("description_kit_instalation")}
                  />
                </div>
                
                <div style={{display: 'flex', alignItems: 'center', gap: '0.6rem', flexWrap: 'wrap'}}>
                  
                  <div className='fv-row' style={{marginBottom: '1.5rem', flex: '1'}}>
                    <label className='d-flex align-items-center fs-6 fw-semibold mb-2'>
                        <span>Total investido (R$)</span>
                        <i
                        className='fas fa-exclamation-circle ms-2 fs-7'
                        data-bs-toggle='tooltip'
                        title='Valor da implantação da usina'
                        ></i>
                    </label>
                    <input
                        type='number'
                        disabled={data && data.plano && data.plano !== "PREMIUM" && data.plano !== "STARTER" ? true : false}
                        className='form-control form-control-lg'
                        name='value_implementation'
                        placeholder=''
                        value={formik.values.value_implementation}
                        onChange={formik.handleChange("value_implementation")}
                    />
                  </div>

                  <div className='fv-row' style={{marginBottom: '1.5rem', flex: '1'}}>
                    <label className='d-flex align-items-center fs-6 fw-semibold mb-2'>
                        <span>Economia total estimada - 25 anos (R$)</span>
                        <i
                        className='fas fa-exclamation-circle ms-2 fs-7'
                        data-bs-toggle='tooltip'
                        title='Economia da usina nos proximos 25 anos'
                        ></i>
                    </label>
                    <input
                        type='number'
                        disabled={data && data.plano && data.plano !== "PREMIUM" && data.plano !== "STARTER" ? true : false}
                        className='form-control form-control-lg'
                        name='economy_total_year'
                        placeholder=''
                        value={formik.values.economy_total_year}
                        onChange={formik.handleChange("economy_total_year")}
                    />
                  </div>
                  
                </div>

                <div className='fv-row' style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                  <label className='d-flex align-items-center fs-6 fw-semibold mb-2' style={{flex: width < 1200 ? 1 : 5}}>
                      <span>Geração Anual Prevista (KWh)</span>
                      <i
                      className='fas fa-exclamation-circle ms-2 fs-7'
                      data-bs-toggle='tooltip'
                      title='Valor da implantação da usina'
                      ></i>
                  </label>
                  <div style={{flex: 1, display: 'flex', gap: '0.5rem'}}>
                    <input
                        type='number'
                        disabled={data && data.plano && data.plano !== "PREMIUM" && data.plano !== "STARTER" ? true : false}
                        className='form-control form-control-lg'
                        name='annual_generation_forecast'
                        placeholder=''
                        value={formik.values.annual_generation_forecast}
                        onChange={formik.handleChange("annual_generation_forecast")}
                    />
                    <button
                        type='button'
                        disabled={data && data.plano && data.plano !== "PREMIUM" && data.plano !== "STARTER" ? true : false}
                        className='btn btn-sm btn-primary'
                        //disabled={loading}
                        //data-kt-stepper-action='submit'
                        onClick={dividerValues}
                    >
                        Aplicar
                    </button>
                  </div>
                </div>

                <div className='fv-row mt-5' style={{display: 'flex', flexDirection: 'column', gap: '1rem', height: '20rem', overflow: 'auto'}}>
                  {/* <label className='d-flex align-items-center fs-6 fw-semibold'>
                      <span>Geração Anual Dividida por Mês (KWh)</span>
                      <i
                      className='fas fa-exclamation-circle ms-2 fs-7'
                      data-bs-toggle='tooltip'
                      title='Valor da implantação da usina'
                      ></i>
                  </label> */}
                  
                  <div style={{display: 'flex', gap: '1rem', flexWrap: width < 1200 ? 'wrap' : 'nowrap'}}>
                    <div style={{display: 'flex', gap: '0.5rem', flex: '1'}}>
                      <label style={{width: '11rem'}} className='d-flex align-items-center fs-6 fw-semibold mb-2'>
                          <span>Janeiro</span>
                      </label>
                      <input
                          type='number'
                          disabled={data && data.plano && data.plano !== "PREMIUM" && data.plano !== "STARTER" ? true : false}
                          className='form-control form-control-lg'
                          name='prog_jan'
                          placeholder=''
                          value={formik.values.prog_jan}
                          onChange={formik.handleChange("prog_jan")}
                      />
                    </div>

                    <div style={{display: 'flex', gap: '0.5rem', flex: '1'}}>
                      <label style={{width: '11rem'}} className='d-flex align-items-center fs-6 fw-semibold mb-2'>
                          <span>Fevereiro</span>
                      </label>
                      <input
                          type='number'
                          disabled={data && data.plano && data.plano !== "PREMIUM" && data.plano !== "STARTER" ? true : false}
                          className='form-control form-control-lg'
                          name='prog_fev'
                          placeholder=''
                          value={formik.values.prog_fev}
                          onChange={formik.handleChange("prog_fev")}
                      />
                    </div>

                    <div style={{display: 'flex', gap: '0.5rem', flex: '1'}}>
                      <label style={{width: '11rem'}} className='d-flex align-items-center fs-6 fw-semibold mb-2'>
                          <span>Março</span>
                      </label>
                      <input
                          type='number'
                          disabled={data && data.plano && data.plano !== "PREMIUM" && data.plano !== "STARTER" ? true : false}
                          className='form-control form-control-lg'
                          name='prog_mar'
                          placeholder=''
                          value={formik.values.prog_mar}
                          onChange={formik.handleChange("prog_mar")}
                      />
                    </div>
                  </div>

                  <div style={{display: 'flex', gap: '1rem', flexWrap: width < 1200 ? 'wrap' : 'nowrap'}}>
                    <div style={{display: 'flex', gap: '0.5rem', flex: '1'}}>
                      <label style={{width: '11rem'}} className='d-flex align-items-center fs-6 fw-semibold mb-2'>
                          <span>Abril</span>
                      </label>
                      <input
                          type='number'
                          disabled={data && data.plano && data.plano !== "PREMIUM" && data.plano !== "STARTER" ? true : false}
                          className='form-control form-control-lg'
                          name='prog_abr'
                          placeholder=''
                          value={formik.values.prog_abr}
                          onChange={formik.handleChange("prog_abr")}
                      />
                    </div>

                    <div style={{display: 'flex', gap: '0.5rem', flex: '1'}}>
                      <label style={{width: '11rem'}} className='d-flex align-items-center fs-6 fw-semibold mb-2'>
                          <span>Maio</span>
                      </label>
                      <input
                          type='number'
                          disabled={data && data.plano && data.plano !== "PREMIUM" && data.plano !== "STARTER" ? true : false}
                          className='form-control form-control-lg'
                          name='prog_may'
                          placeholder=''
                          value={formik.values.prog_may}
                          onChange={formik.handleChange("prog_may")}
                      />
                    </div>

                    <div style={{display: 'flex', gap: '0.5rem', flex: '1'}}>
                      <label style={{width: '11rem'}} className='d-flex align-items-center fs-6 fw-semibold mb-2'>
                          <span>Junho</span>
                      </label>
                      <input
                          type='number'
                          disabled={data && data.plano && data.plano !== "PREMIUM" && data.plano !== "STARTER" ? true : false}
                          className='form-control form-control-lg'
                          name='prog_jun'
                          placeholder=''
                          value={formik.values.prog_jun}
                          onChange={formik.handleChange("prog_jun")}
                      />
                    </div>
                  </div>

                  <div style={{display: 'flex', gap: '1rem', flexWrap: width < 1200 ? 'wrap' : 'nowrap'}}>
                    <div style={{display: 'flex', gap: '0.5rem', flex: '1'}}>
                      <label style={{width: '11rem'}} className='d-flex align-items-center fs-6 fw-semibold mb-2'>
                          <span>Julho</span>
                      </label>
                      <input
                          type='number'
                          disabled={data && data.plano && data.plano !== "PREMIUM" && data.plano !== "STARTER" ? true : false}
                          className='form-control form-control-lg'
                          name='prog_jul'
                          placeholder=''
                          value={formik.values.prog_jul}
                          onChange={formik.handleChange("prog_jul")}
                      />
                    </div>
                    
                    <div style={{display: 'flex', gap: '0.5rem', flex: '1'}}>
                      <label style={{width: '11rem'}} className='d-flex align-items-center fs-6 fw-semibold mb-2'>
                          <span>Agosto</span>
                      </label>
                      <input
                          type='number'
                          disabled={data && data.plano && data.plano !== "PREMIUM" && data.plano !== "STARTER" ? true : false}
                          className='form-control form-control-lg'
                          name='prog_ago'
                          placeholder=''
                          value={formik.values.prog_ago}
                          onChange={formik.handleChange("prog_ago")}
                      />
                    </div>

                    <div style={{display: 'flex', gap: '0.5rem', flex: '1'}}>
                      <label style={{width: '11rem'}} className='d-flex align-items-center fs-6 fw-semibold mb-2'>
                          <span>Setembro</span>
                      </label>
                      <input
                          type='number'
                          disabled={data && data.plano && data.plano !== "PREMIUM" && data.plano !== "STARTER" ? true : false}
                          className='form-control form-control-lg'
                          name='prog_sep'
                          placeholder=''
                          value={formik.values.prog_sep}
                          onChange={formik.handleChange("prog_sep")}
                      />
                    </div>
                  </div>

                  <div style={{display: 'flex', gap: '1rem', flexWrap: width < 1200 ? 'wrap' : 'nowrap'}}>
                    <div style={{display: 'flex', gap: '0.5rem', flex: '1'}}>
                      <label style={{width: '11rem'}} className='d-flex align-items-center fs-6 fw-semibold mb-2'>
                          <span>Outubro</span>
                      </label>
                      <input
                          type='number'
                          disabled={data && data.plano && data.plano !== "PREMIUM" && data.plano !== "STARTER" ? true : false}
                          className='form-control form-control-lg'
                          name='prog_oct'
                          placeholder=''
                          value={formik.values.prog_oct}
                          onChange={formik.handleChange("prog_oct")}
                      />
                    </div>

                    <div style={{display: 'flex', gap: '0.5rem', flex: '1'}}>
                      <label style={{width: '11rem'}} className='d-flex align-items-center fs-6 fw-semibold mb-2'>
                          <span>Novembro</span>
                      </label>
                      <input
                          type='number'
                          disabled={data && data.plano && data.plano !== "PREMIUM" && data.plano !== "STARTER" ? true : false}
                          className='form-control form-control-lg'
                          name='prog_nov'
                          placeholder=''
                          value={formik.values.prog_nov}
                          onChange={formik.handleChange("prog_nov")}
                      />
                    </div>
                    
                    <div style={{display: 'flex', gap: '0.5rem', flex: '1'}}>
                      <label style={{width: '11rem'}} className='d-flex align-items-center fs-6 fw-semibold mb-2'>
                          <span>Dezembro</span>
                      </label>
                      <input
                          type='number'
                          disabled={data && data.plano && data.plano !== "PREMIUM" && data.plano !== "STARTER" ? true : false}
                          className='form-control form-control-lg'
                          name='prog_dez'
                          placeholder=''
                          value={formik.values.prog_dez}
                          onChange={formik.handleChange("prog_dez")}
                      />
                    </div>
                  </div>
                </div>


                <div className='fv-row mt-5'>
                  <div className='fv-row' style={{marginBottom: '1.5rem', flex: '1'}}>
                    <label className='d-flex align-items-center fs-6 fw-semibold mb-2'>
                        <span>Endereço</span>
                        <i
                        className='fas fa-exclamation-circle ms-2 fs-7'
                        data-bs-toggle='tooltip'
                        title='Valor da implantação da usina'
                        ></i>
                    </label>
                    <input
                      type="text"
                      placeholder="Digite o endereço"
                      className='form-control form-control-lg'
                      name='endereco'
                      value={formik.values.address}
                      onChange={(e) => {
                        //setQuery(e.target.value);
                        formik.setValues({...formik.values, address: e.target.value})
                        fetchSuggestions(e.target.value);
                      }}
                    />
                    <ul style={{ listStyleType: "none", padding: 0, marginTop: "10px" }}>
                      {suggestions.map((suggestion: any, index) => (
                        <li
                          key={index}
                          style={{
                            padding: "10px",
                            borderBottom: "1px solid #ccc",
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            setQuery(suggestion.place_name);

                            formik.setValues({
                              ...formik.values, 
                              address: suggestion.place_name, 
                              plantLat: suggestion.geometry.coordinates[1], 
                              plantLon: suggestion.geometry.coordinates[0]
                            })
                            setSuggestions([]);
                          }}
                        >
                          {suggestion.place_name}
                        </li>
                      ))}
                    </ul>
                  </div>

                  <div>
                    <MapsV2Plant plant={data} formik={formik}/>
                  </div>

                  <div style={{display: 'flex', gap: '2rem', flexWrap: 'wrap'}}>
                    <div className='fv-row' style={{marginBottom: '1.5rem', flex: '1', display: 'flex', gap: '0.5rem', minWidth: '15rem'}}>
                      <label className='d-flex align-items-center fs-6 fw-semibold mb-2'>
                          <span>Latitude</span>
                          <i
                          className='fas fa-exclamation-circle ms-2 fs-7'
                          data-bs-toggle='tooltip'
                          title='Latitude da usina'
                          ></i>
                      </label>
                      <input
                          type='number'
                          disabled
                          //disabled={data && data.plano && data.plano !== "PREMIUM" ? true : false}
                          className='form-control form-control-lg'
                          name='Latitude da usina'
                          placeholder=''
                          value={formik.values.plantLat}
                          onChange={formik.handleChange("plantLat")}
                      />
                    </div>

                    <div className='fv-row' style={{marginBottom: '1.5rem', flex: '1', display: 'flex', gap: '0.5rem', minWidth: '15rem'}}>
                      <label className='d-flex align-items-center fs-6 fw-semibold mb-2'>
                          <span>Longitude</span>
                          <i
                          className='fas fa-exclamation-circle ms-2 fs-7'
                          data-bs-toggle='tooltip'
                          title='Valor da implantação da usina'
                          ></i>
                      </label>
                      <input
                          type='number'
                          //disabled={data && data.plano && data.plano !== "PREMIUM" ? true : false}
                          disabled
                          className='form-control form-control-lg'
                          name='Longitude da usina'
                          placeholder=''
                          value={formik.values.plantLon}
                          onChange={formik.handleChange("plantLon")}
                      />
                    </div>
                  </div>
                </div>


            </div>
            {/*begin::Form */}
            
            {/*end::Form */}
          </div>
          {/*end::Content */}
        </div>
        {/* end::Stepper */}
      </div>

      <div className='modal-footer py-lg-10 px-lg-10'>
        <div style={{display: 'flex', justifyContent: 'space-between', width: '100%'}}>
            <button
                type='button'
                className='btn btn-lg btn-light'
                disabled={loading}
                onClick={() => {
                    closed()
                }}
                //data-kt-stepper-action='submit'
            >
                <KTSVG
                    path='/media/icons/duotune/arrows/arr063.svg'
                    className='svg-icon-3 ms-2 me-0'
                />
                <span style={{marginLeft: '0.5rem'}}>Cancelar</span>
                
            </button>

            <button
                type='button'
                className='btn btn-lg btn-primary'
                disabled={loading}
                //data-kt-stepper-action='submit'
                onClick={submit}
            >
                Atualizar
                {
                    loading ? 
                    <LoadingSpan style={{marginLeft: '0.5rem'}}/>
                    :
                    <KTSVG
                        path='/media/icons/duotune/arrows/arr064.svg'
                        className='svg-icon-3 ms-2 me-0'
                    />
                }
            </button>
        </div>
      </div>
    </Modal>,
    modalsRoot
  )
}

export {ModalUpdatePlant}
