import {useState} from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import {Link} from 'react-router-dom'
import {useFormik} from 'formik'
import {requestPassword, verificadPassword} from '../core/_requests'
import { toAbsoluteUrl } from '../../../../_metronic/helpers'

const initialValues = {
  email: '',
}

const forgotPasswordSchema = Yup.object().shape({
  email: Yup.string()
    .email('Formato de e-mail incorreto')
    .min(3, 'Máximo 3 caracteres')
    .max(50, 'Máximo 50 caracteres')
    .required('Email é obrigatório'),
})

export function ForgotPassword() {
  const [loading, setLoading] = useState(false)
  const [hasErrors, setHasErrors] = useState<boolean | undefined>(undefined)



  const formik = useFormik({
    initialValues,
    validationSchema: forgotPasswordSchema,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      setLoading(true)
      setHasErrors(undefined)
      try {
        await verificadPassword(values.email);
        setHasErrors(false)
        setLoading(false)

      } catch (error: any) {
        setHasErrors(true)
        setLoading(false)
        setSubmitting(false)
        setStatus(error && error.response 
          && error.response.data 
          && error.response.data.message 
          ? error.response.data.message 
          : `E-mail incorreto ou inexistente na plataforma!`)
      }

      // setTimeout(() => {
      //   requestPassword(values.email)
      //     .then(({data: {result}}) => {
      //       setHasErrors(false)
      //       setLoading(false)
      //     })
      //     .catch(() => {
      //       setHasErrors(true)
      //       setLoading(false)
      //       setSubmitting(false)
      //       setStatus('The login detail is incorrect')
      //     })
      // }, 1000)
    },
  })

  return (
    <form
      className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
      noValidate
      id='kt_login_password_reset_form'
      onSubmit={formik.handleSubmit}
    >
      <div className='text-center mb-5'>
        {/* begin::Title */}
        {/* <h1 className='text-dark fw-bolder mb-3'>Forgot Password ?</h1> */}
        <Link to='/' className='mb-7'>
            <img alt='Logo' src={toAbsoluteUrl('/media/imgs/novaLogoBlack.png')} className='h-60px' />
        </Link>
        {/* end::Title */}

        {/* begin::Link */}
        <div className='text-gray-500 fw-semibold fs-6 mt-5'>
          
        </div>

        <div className='mb-10 bg-light-primary p-5 rounded border border-dashed border-primary'>
          <div className='text-primary fs-7 text-center'>
            Digite seu <strong>e-mail vinculado a uma conta</strong> para redefinir sua senha.
          </div>
        </div>
        {/* end::Link */}
      </div>

      {/* begin::Title */}
      {hasErrors === true && (
        <div className='mb-lg-10 alert alert-danger'>
          <div className='alert-text font-weight-bold'>
            {formik.status}
          </div>
        </div>
      )}

      {hasErrors === false && (
        <div className='mb-10 bg-light-success p-8 rounded'>
          <div className='text-success'>Enviado redefinição de senha. Por favor, verifique seu e-mail</div>
        </div>
      )}
      {/* end::Title */}

      {/* begin::Form group */}
      <div className='fv-row mb-8'>
        <label className='form-label fw-bolder text-gray-900 fs-6'>E-mail</label>
        <input
          type='email'
          placeholder=''
          autoComplete='off'
          {...formik.getFieldProps('email')}
          className={clsx(
            'form-control bg-transparent',
            {'is-invalid': formik.touched.email && formik.errors.email},
            {
              'is-valid': formik.touched.email && !formik.errors.email,
            }
          )}
        />
        {formik.touched.email && formik.errors.email && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.email}</span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Form group */}
      <div className='d-flex flex-wrap justify-content-center pb-lg-0 gap-2'>
        <Link to='/auth/login'>
          <button
            type='button'
            id='kt_login_password_reset_form_cancel_button'
            className='btn btn-light'
            disabled={formik.isSubmitting || !formik.isValid}
          >
            Cancelar
          </button>
        </Link>{' '}

        <button type='submit' id='kt_password_reset_submit' className='btn btn-primary ' disabled={loading}>
          <span className='indicator-label'>Enviar</span>
          {loading && (
            <span className='indicator-progress'>
              Enviando ...
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>
      </div>
      {/* end::Form group */}
    </form>
  )
}


